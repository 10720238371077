// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
// components 
import ConfirmationBox from '../../components/item-deletion-confirm-box';
// config & functions 
import config from '../../config';

function Table({list, token, handleondeletefn}) {
    const [RowId, setRowId] = useState(0);
    const deleteUrl = `${config.api.generic}/clients`;
    const [ListData, setListData] = useState([]);

    useEffect(() => {
        setListData(list)
    }, [ListData])

    if(ListData && Array.isArray(ListData) && ListData.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table fs-14rem">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Phone No</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Generated By</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ListData.map(data => {
                            return <React.Fragment key={Math.random()} ><tr className='bg-light'>
                                    <td style={{minWidth: '160px'}}>{data.first_name} {data.last_name}</td>
                                    <td>{data.phone_no}</td>
                                    <td style={{minWidth: '320px'}}>{data.address} {data.city_label} {data.state_label}</td>
                                    <td style={{minWidth: '80px'}}>{data.status_text}</td>
                                    <td style={{minWidth: '220px'}}>{data.generated_by} - ({data.date_time})</td>
                                    <td style={{minWidth: '94px'}}>
                                        <a href={`/clients/edit/${data.id}`} className='text-decoration-none p-2'><FontAwesomeIcon icon={faPen} className="dashboard-card-icon" /></a>
                                        <a href="#" onClick={() => setRowId(data.id)} className="ms-2 p-2 text-decoration-none"><FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></a>
                                    </td>
                                </tr>
                            </React.Fragment>
                        })}
                    </tbody>
                </table>
            </div>
            {(RowId) ? <ConfirmationBox url={deleteUrl} id={RowId} setrowid={setRowId} token={token} handleondeletefn={handleondeletefn} /> : null}
        </>
    } else {
        return null;
    }
}

export default Table;