// libs
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
// components
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Breadcrumb from "../../components/breadcrumb";
import AddMenu from './add-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmationBox from './confirmation-box/menu-deletion';
import SplitPrice from './split-price';
import AllPayments from './all-payments';
// css
import "../../assets/css/form.css";
import "react-datepicker/dist/react-datepicker.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";
import utils from '../../utils';

function Quotation({token, orgInfo}) {
    // Temp added, need to validate properly
    if(orgInfo && orgInfo.hasOwnProperty('industryId') && parseInt(orgInfo.industryId) === 1) {
        window.location.href="/dashboard";
    }
    const { clientId, quotationId } = useParams();
    const [Refresh, setRefresh] = useState(false);
    const [InputFields, setInputFields] = useState({
        client: [],
        startDate: new Date(),
        endDate: new Date(),
        referenceName: "",
        menuItems: [],
        menuItemsEditIndex: "",
        terms: "",
        quotationPrice: {
            type: 'auto',
            splitPrice: [],
            totalPrice: 0,
            allPayments: [],
            totalPaid: 0,
            gst: 0,
            discount: 0
        },
        quotationStatus: 2
        // Default quotationStatus will be 2 to edit
    });

    const [RemoveMenuId, setRemoveMenuId] = useState("");
    const [SubmitButton, setSubmitButton] = useState((quotationId) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (quotationId) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [LoadMenu, setLoadMenu] = useState(false);
    const [LoadSplitPrice, setLoadSplitPrice] = useState(false);
    const [LoadAllPayments, setLoadAllPayments] = useState(false);
    const [isUnsavedChanges, setisUnsavedChanges] = useState(false);
    const [ClientList, setClientList] = useState([]);
    const [LoadingClientList, setLoadingClientList] = useState(true);
    const [ErrorClientList, setErrorClientList] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const handleStateConfig = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val;
        setInputFields(newData);
    });

    const handleStateQPConfig = ((key, val) => {
        const newData = { ...InputFields }
        let v = val==""?0:val;
        newData.quotationPrice[key] = v;
        setInputFields(newData);
    });

    const handleSplitPrice = (val) => {
        if(parseInt(val.total) > 0) {
            handleStateQPConfig('totalPrice', parseInt(val.total));
            handleStateQPConfig('splitPrice', val.list);
            reset({totalAmount: parseInt(val.total)});
        } else {
            handleStateQPConfig('totalPrice', 0);
            handleStateQPConfig('splitPrice', []);
            reset({totalAmount: 0});
        }
    }

    const handleAllPayments = (val) => {
        if(parseInt(val.total) > 0) {
            handleStateQPConfig('totalPaid', parseInt(val.total));
            handleStateQPConfig('allPayments', val.list);
        } else {
            handleStateQPConfig('totalPaid', 0);
            handleStateQPConfig('allPayments', []);
        }
    }

    const updateChangeEvent = (value, field) => {
        setisUnsavedChanges(true);
        handleStateConfig(field.name, value);
        field.onChange(value);
	}

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        if(Array.isArray(InputFields.client) && InputFields.client.length > 0) {
            if(Array.isArray(InputFields.menuItems) && InputFields.menuItems.length > 0) {
                let sp = (Array.isArray(InputFields.quotationPrice.splitPrice) && InputFields.quotationPrice.splitPrice.length > 0) ? JSON.stringify(InputFields.quotationPrice.splitPrice) : '';
                let ap = (Array.isArray(InputFields.quotationPrice.allPayments) && InputFields.quotationPrice.allPayments.length > 0) ? JSON.stringify(InputFields.quotationPrice.allPayments) : '';
                let postData = {
                    quotationId: quotationId,
                    clientId: InputFields.client[0].id,
                    startDate: InputFields.startDate,
                    endDate: InputFields.endDate,
                    referenceName: data.referenceName,
                    menuItems: JSON.stringify(InputFields.menuItems),
                    type: 'auto',
                    splitPrice: sp,
                    allPayments: ap,
                    totalPrice: InputFields.quotationPrice.totalPrice,
                    totalPaid: InputFields.quotationPrice.totalPaid,
                    discount: InputFields.quotationPrice.discount,
                    gst: InputFields.quotationPrice.gst,
                    quotationStatus: InputFields.quotationStatus,
                    terms: data.terms
                }

                let apiCall = "";
                if(quotationId) {
                    apiCall = await ajaxCall.patchCall(`${config.api.generic}/quotation`, postData, token);
                } else {
                    apiCall = await ajaxCall.postCall(`${config.api.generic}/quotation`, postData, token);
                }
                if(apiCall && apiCall.status) {
                    toast.info('Record updated successfully. Redirecting...');
                    reset();
                    setisUnsavedChanges(false);
                    setSubmitButton((quotationId) ? 'UPDATE RECORD' : 'SAVE RECORD');
                    setDisableSubmitButton(false);
                    setTimeout(() => {
                        window.location.href = "/quotation";
                    }, 1500);
                } else {
                    let msg = (apiCall && apiCall.hasOwnProperty('context')) ? apiCall.context.message : "Something went wrong";
                    toast.error(msg);
                    setSubmitButton((quotationId) ? 'UPDATE RECORD' : 'SAVE RECORD');
                    setDisableSubmitButton(false);
                }
            } else {
                toast.error('Please choose your menu items');
                setSubmitButton((quotationId) ? 'UPDATE RECORD' : 'SAVE RECORD');
                setDisableSubmitButton(false);
            }
        } else {
            toast.error('Please choose client');
            setSubmitButton((quotationId) ? 'UPDATE RECORD' : 'SAVE RECORD');
            setDisableSubmitButton(false);
        }
    }

    const handleMenuItems = (val, mode) => {
        if(mode == "add") {
            const newData = { ...InputFields }
            newData.menuItems.push(val);
            setisUnsavedChanges(true);
            setInputFields(newData);
            setRefresh(!Refresh);
        } else if (mode == "update" && InputFields.menuItemsEditIndex !== "") {
            const newData = { ...InputFields }
            newData.menuItemsEditIndex = "";
            newData.menuItems[InputFields.menuItemsEditIndex] = val;
            setisUnsavedChanges(true);
            setInputFields(newData);
            setRefresh(!Refresh);
        }
	}

    const editItems = (i) => {
        setisUnsavedChanges(true);
        handleStateConfig('menuItemsEditIndex', i);
        setLoadMenu(true);
    }

    const goBack = () => {
        if(isUnsavedChanges) {
            toast.error('Please save your changes before leaving this page');
        } else {
            window.location.href = "/quotation";
        }
    }

    const FormatedAMPM = ({date}) => {
        return utils.getYYMMDD_AMPM(date)
    }

    // Get data from DB - Onload
    React.useEffect(() => {
        async function fetchData() {
            if(quotationId) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/quotation/row`, {
                    quotationId: (quotationId) ? quotationId : 0,
                    clientId : (clientId) ? clientId : 0
                }, token);
                if(apiCall && apiCall.status) {
                    let sp = [], mi = [], ap = [];
                    if(apiCall.context.data[0].split_total_amount && apiCall.context.data[0].split_total_amount !== "") {
                        sp = JSON.parse(apiCall.context.data[0].split_total_amount);
                    }
                    if(apiCall.context.data[0].menu_items && apiCall.context.data[0].menu_items !== "") {
                        mi = JSON.parse(apiCall.context.data[0].menu_items);
                    }
                    if(apiCall.context.data[0].all_payments && apiCall.context.data[0].all_payments !== "") {
                        ap = JSON.parse(apiCall.context.data[0].all_payments);
                    }
                    let onLoadData = {
                        client: [{id: apiCall.context.data[0].client_id, label: apiCall.context.data[0].client_label}],
                        startDate: new Date(apiCall.context.data[0].start_date),
                        endDate: new Date(apiCall.context.data[0].end_date),
                        referenceName: apiCall.context.data[0].reference_name,
                        menuItems: mi,
                        menuItemsEditIndex: "",
                        quotationPrice: {
                            type: 'manual',
                            splitPrice: sp,
                            totalPrice: parseInt(apiCall.context.data[0].total_amount || 0),
                            discount: parseInt(apiCall.context.data[0].discount || 0),
                            gst: parseInt(apiCall.context.data[0].gst || 0),
                            allPayments: ap,
                            totalPaid: parseInt(apiCall.context.data[0].total_paid || 0),
                            gst: parseInt(apiCall.context.data[0].gst || 0),
                        },
                        quotationStatus: apiCall.context.data[0].quotation_status_id || 2,
                        terms: apiCall.context.data[0].terms
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false, message: '' })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }  
            } 
        }
        // Get clients list
        async function clientsList() {
            let apiCall = await ajaxCall.getCall(`${config.api.generic}/clients/autocomplete`, {}, token);
            if(apiCall && apiCall.status) {
                setClientList(apiCall.context.data);
                setLoadingClientList(false);
            } else {
                setLoadingClientList(false);
                setErrorClientList(true);
            } 
        }
        fetchData();
        clientsList();
    }, []);

    const tp = parseInt(InputFields.quotationPrice.totalPrice);
    const td = parseInt(InputFields.quotationPrice.discount);
    const gst = parseInt(InputFields.quotationPrice.gst);
    const totalPaid = parseInt(InputFields.quotationPrice.totalPaid);

    const totalGST = (tp * parseInt(gst)/100)+tp;
    const grandTotalAfterDiscount = totalGST * ((100-td) / 100);
    const printTotal = utils.numberWithCommas(grandTotalAfterDiscount);
    const printPaid = utils.numberWithCommas(totalPaid);
    const printBalance = utils.numberWithCommas(grandTotalAfterDiscount-totalPaid);
    const isPriceSplited = (InputFields.quotationPrice?.splitPrice && Array.isArray(InputFields.quotationPrice.splitPrice) && InputFields.quotationPrice.splitPrice.length > 0) ? true : false;

    return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Quotation</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Quotation', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <button type='button' className="rwt-primary-btn btn-md btn btn-outline-none text-white fs-14rem" onClick={goBack}>QUOTATIONS</button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1 ">
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-lg-5 p-md-2 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {(Notification.status) ?
                                            <div className="col-12 text-center">{Notification.message}</div> : <>
                                                <div className="col-md-12">
                                                    <div className="text-left mt-3 rwt-txt-dark-blue">
                                                        <h3><b>Event Details</b></h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                                                    <label className="form-label fs-14rem">Client Name</label>
                                                    {(LoadingClientList)?<div className='fs-14rem text-primary'>Loading client list...</div>:(ErrorClientList)?<div className='fs-14rem text-danger'>Cannot fetch client list. Please refresh</div>:<>
                                                            <Controller
                                                                control={control}
                                                                name="client"
                                                                rules={{required: true}}
                                                                render={({ field, fieldState }) => (
                                                                    <Typeahead
                                                                        {...field}
                                                                        onChange={(e) => {
                                                                            if(e && Array.isArray(e) && e.length > 0) {
                                                                                updateChangeEvent([{id: e[0].id, label: e[0].label }], field)
                                                                            } else {
                                                                                updateChangeEvent([], field)
                                                                            }
                                                                        }}
                                                                        selected={InputFields.client} 
                                                                        id="client-list"
                                                                        clearButton
                                                                        className={""}
                                                                        aria-describedby="client name"
                                                                        options={ClientList}
                                                                    />
                                                                )}
                                                            />
                                                            <small className="form-text text-danger">
                                                                {errors.client?.type === "required" && "Client selection is mandatory"}
                                                            </small>
                                                        </>
                                                    }
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-6 mt-3">
                                                    <label className="form-label fs-14rem">Event Start Date</label>
                                                    <Controller
                                                        name='startDate' 
                                                        defaultValue={InputFields && InputFields.startDate || new Date()}
                                                        rules={{required: true}}
                                                        control={control} 
                                                        render={({field}) => (
                                                            <DatePicker 
                                                                selected={field.value} 
                                                                placeholderText="Start Date" 
                                                                className="form-control" 
                                                                format="MM-dd-y" 
                                                                onChange={(e) => {
                                                                    updateChangeEvent(e, field)
                                                                }}
                                                            />
                                                        )}
                                                    />	
                                                    <small className="form-text text-danger">
                                                        {errors.startDate?.type === "required" && "Start Date is mandatory"}
                                                    </small>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-sm-6 col-6 mt-3">
                                                    <label className="form-label fs-14rem">Event End Date</label>
                                                    <Controller
                                                        name='endDate' 
                                                        defaultValue={InputFields && InputFields.endDate || new Date()}
                                                        rules={{required: true}}
                                                        control={control} 
                                                        format="MM-dd-y"  
                                                        render={({field}) => (
                                                            <DatePicker 
                                                                selected={field.value} 
                                                                placeholderText="End Date" 
                                                                className="form-control" 
                                                                format="MM-dd-y" 
                                                                onChange={(e) => {
                                                                    updateChangeEvent(e, field)
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <small className="form-text text-danger">
                                                        {errors.endDate?.type === "required" && "End Date is mandatory"}
                                                    </small>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                                                    <label className="form-label fs-14rem">Reference/Event Name</label>
                                                    <input type="text" placeholder='Birthday, Marriage, etc' defaultValue={InputFields.referenceName || ''} className="form-control" name="referenceName" {...register("referenceName", { required: {
                                                        value: true,
                                                        message: "Reference/Event name is mandatory"
                                                    }, minLength: {
                                                        value: 2,
                                                        message: "Minimum 2 characters allowed"
                                                    }, maxLength: {
                                                        value: 24,
                                                        message: "Max 24 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.referenceName && errors.referenceName.message}
                                                    </small>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                                                    <label className="form-label fs-14rem">Quotation Status</label>
                                                    <select {...register("quotationStatus", { required: true })} value={InputFields.quotationStatus} className="form-control custom-select" onChange={(e) => handleStateConfig('quotationStatus', e.target.value)}>
                                                        <option value={''}></option>
                                                        <option value={1}>Approve Now</option>
                                                        <option value={2}>Approve Later</option>
                                                        <option value={3}>Hide</option>
                                                    </select>
                                                    <small className="form-text text-danger">
                                                        {errors.quotationStatus?.type === "required" && "Status is mandatory"}
                                                    </small>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="text-left mt-4 mb-3 rwt-txt-dark-blue">
                                                        <h3><b>Menu Details</b></h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    {(Array.isArray(InputFields.menuItems) && InputFields.menuItems.length > 0)? <div className='row'>
                                                        {InputFields.menuItems.map((data, i) => {
                                                            return <div key={Math.random()} className='col-xs-12 col-sm-6 col-md-6 col-lg-4'>
                                                                <div className='shadow-sm mb-3 border border-1'>
                                                                    <div className='p-2'>
                                                                        <div className='fw-bold fs-16rem'><FormatedAMPM date={data.startDate} /></div>
                                                                        <div className='fw-normal fs-14rem'>Total members: {data.totalMembers}</div>
                                                                        <div className='fw-normal fs-14rem'>Items selected: {data.selectedItems.length}</div>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <button type='button' className='bg-white border-0 border-top border-end flex-fill rwt-txt-blue p-2 fs-4rem fw-bold text-center' onClick={e=>editItems(i)}><FontAwesomeIcon icon={faEdit} className="" /> Edit</button>
                                                                        <button type='button' className='bg-white border-0 border-top border-end flex-fill text-danger p-2 fs-4rem fw-bold text-center' onClick={e=>setRemoveMenuId(i)}><FontAwesomeIcon icon={faTrash} className="" /> Delete</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>:null}
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="text-center mt-3 mb-2">
                                                        <button type="button" className="rounded-circle border btn rwt-primary-btn fs-22rem" onClick={() => editItems('')} style={{width: '65px', height: '65px'}}>+</button>
                                                        <div className="text-center text-dark fw-bold">Add Menu</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="text-left my-3 rwt-txt-dark-blue d-flex justify-content-between align-items-center">
                                                        <h3><b>Price</b></h3>
                                                        <div>
                                                            <button type='button' className="bg-white border border-1 fw-bold" onClick={() => setLoadSplitPrice(true)}>{isPriceSplited?<span className='rwt-txt-blue fs-14rem'>View Split Price</span>:<span className='rwt-txt-orange fs-14rem'>Split Price</span>}</button>
                                                            <button type='button' className="bg-white border border-1 fw-bold" onClick={() => setLoadAllPayments(true)}><span className='rwt-txt-blue fs-14rem'>Payments</span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='row'>
                                                        <div className="col">
                                                            <label className="form-label fs-14rem">Total Amount</label>
                                                            <input type="text" placeholder='Total amount' disabled={isPriceSplited} defaultValue={InputFields.quotationPrice.totalPrice || 0} className="form-control" name="totalAmount" {...register("totalAmount", { required: {
                                                                value: !isPriceSplited,
                                                                message: "Total amount is mandatory"
                                                            }, minLength: {
                                                                value: 1,
                                                                message: "Minimum 1 characters allowed"
                                                            }, maxLength: {
                                                                value: 9,
                                                                message: "Max 9 characters allowed"
                                                            }, pattern: {
                                                                value: /^[0-9+-]+$/,
                                                                message: "Enter valid amount!"
                                                            }, onChange:(e) => handleStateQPConfig('totalPrice', e.target.value) })} />
                                                            <small className="form-text text-danger">
                                                                {errors.totalAmount && errors.totalAmount.message}
                                                            </small>
                                                        </div>
                                                        <div className="col">
                                                            <label className="form-label fs-14rem">GST (%)</label>
                                                            <input type="text" placeholder='GST (%)' defaultValue={InputFields.quotationPrice.gst || 0} className="form-control" name="gst" {...register("gst", { required: {
                                                                value: true,
                                                                message: "GST is mandatory"
                                                            }, minLength: {
                                                                value: 1,
                                                                message: "Minimum 1 characters allowed"
                                                            }, maxLength: {
                                                                value: 2,
                                                                message: "Max 2 characters allowed"
                                                            }, pattern: {
                                                                value: /^[0-9+-]+$/,
                                                                message: "Enter valid GST!"
                                                            }, onChange:(e) => handleStateQPConfig('gst', e.target.value) })} />
                                                            <small className="form-text text-danger">
                                                                {errors.gst && errors.gst.message}
                                                            </small>
                                                        </div>
                                                        <div className="col">
                                                            <label className="form-label fs-14rem">Discount (%)</label>
                                                            <input type="text" placeholder='Total discount (%)' defaultValue={InputFields.quotationPrice.discount || 0} className="form-control" name="totalDiscount" {...register("totalDiscount", { required: {
                                                                value: true,
                                                                message: "Discount is mandatory"
                                                            }, minLength: {
                                                                value: 1,
                                                                message: "Minimum 1 characters allowed"
                                                            }, maxLength: {
                                                                value: 2,
                                                                message: "Max 2 characters allowed"
                                                            }, pattern: {
                                                                value: /^[0-9+-]+$/,
                                                                message: "Enter valid Discount!"
                                                            }, onChange:(e) => handleStateQPConfig('discount', e.target.value) })} />
                                                            <small className="form-text text-danger">
                                                                {errors.totalDiscount && errors.totalDiscount.message}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="mt-3">
                                                                <div className="fs-16rem fw-bold">Grand Total: </div><div className="fs-16rem fw-bold rwt-txt-orange">{printTotal}/-</div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mt-3">
                                                                <div className="fs-16rem fw-bold">Paid: </div><div className="fs-16rem fw-bold rwt-txt-orange">{printPaid}/-</div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mt-3">
                                                                <div className="fs-16rem fw-bold">Balance: </div><div className="fs-16rem fw-bold rwt-txt-orange">{printBalance}/-</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="text-left mt-4 mb-2 rwt-txt-dark-blue d-flex justify-content-between align-items-center">
                                                                <h3><b>Terms & Conditions</b></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div>
                                                                <textarea defaultValue={InputFields.terms || ''} className="form-control" name="terms" {...register("terms", { required: {
                                                                value: false,
                                                                message: "Terms is mandatory"
                                                            }, minLength: {
                                                                value: 3,
                                                                message: "Minimum 3 characters allowed"
                                                            }, maxLength: {
                                                                value: 500,
                                                                message: "Max 500 characters allowed"
                                                            }})}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                </div>
                                            </>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
            {(LoadMenu)? <AddMenu industryid={orgInfo.industryId} token={token} orgfoodtype={orgInfo.foodTypeId} domain={orgInfo.domain} handlemenuitemsfn={handleMenuItems} parentinputfields={InputFields} setloadmenu={setLoadMenu} />:null}
            {(RemoveMenuId!=="")? <ConfirmationBox setremovemenuid={setRemoveMenuId} removemenuid={RemoveMenuId} parentinputfields={InputFields} setparentinputfields={setInputFields} refresh={Refresh} setrefresh={setRefresh} />:null}
            {(LoadSplitPrice)? <SplitPrice predata={InputFields.quotationPrice.splitPrice} setloadsplitprice={setLoadSplitPrice} handlesplitprice={handleSplitPrice} />:null}
            {(LoadAllPayments)? <AllPayments predata={InputFields.quotationPrice.allPayments} setloadallpayments={setLoadAllPayments} handleallpayments={handleAllPayments} />:null}
        </>
    )
}

export default Quotation;