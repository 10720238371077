// libs
import React, { useState } from 'react';
// components
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Breadcrumb from "../../components/breadcrumb";
import GalleryBox from '../../components/gallery-box';
import Category from "../../components/category";
import Tag from '../../components/tag';
import ImagePreviewer from '../../components/image-previewer';
// css
import "../../assets/css/form.css";
import "react-datepicker/dist/react-datepicker.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";

function Form({token, orgInfo}) {
    const { productId } = useParams();

    const [InputFields, setInputFields] = useState({
        productId: "",
        categoryId: "",
        name: "",
        image: "",
        mrp: 0,
        discount: 0,
        productStatusId: "",
        description: "",
        tagId: ""
    });
    const [SubmitButton, setSubmitButton] = useState((productId) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (productId) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [PreviewImage, setPreviewImage] = useState('');
    const [BrowseImage, setBrowseImage] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const handleStateConfig = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const handleStatusChange=((val)=>{ 
        handleStateConfig('productStatusId', val.target.value) 
    })
    
    const handleFoodTypeChange=((val)=>{ 
        handleStateConfig('foodTypeId', val.target.value) 
    })

    const handleProductImageName=((val)=>{ 
        handleStateConfig('image', val) 
    })

    const removeProductImage = () => {
        handleStateConfig('image', ''); 
    }

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);

        let postData = {
            productId: productId,
            categoryId: data.categoryId,
            name: data.name,
            image: InputFields.image,
            mrp: data.mrp,
            discount: data.discount,
            productStatusId: data.productStatusId,
            description: data.description,
            searchKeyword: data.searchKeyword,
            tagId: data.tagId
        }
        let apiCall = "";
        if(InputFields.productId) {
            apiCall = await ajaxCall.patchCall(`${config.api.generic}/products`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${config.api.generic}/products`, postData, token);
        }
        if(apiCall && apiCall.status) {
            setSubmitButton((InputFields.productId)?'UPDATE RECORD':'SAVE RECORD');
            toast.info('Record updated successfully');
            if(!InputFields.productId) {
                reset();
            }
            setDisableSubmitButton(false);
        } else {
            setSubmitButton((InputFields.productId)?'UPDATE RECORD':'SAVE RECORD');
            let msg = (apiCall && apiCall.hasOwnProperty('context')) ? apiCall.context.message : "Something went wrong";
            toast.error(msg);
            setDisableSubmitButton(false);
        }
    }

    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function fetchData() {
            if (productId) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/products/row`, {
                    productId: (productId) ? productId : 0
                }, token);
                if(apiCall && apiCall.status) {
                    let onLoadData = {
                        productId: productId,
                        categoryId: apiCall.context.data[0].category_id,
                        name: apiCall.context.data[0].name,
                        image: apiCall.context.data[0].image,
                        mrp: apiCall.context.data[0].mrp,
                        discount: apiCall.context.data[0].discount,
                        productStatusId: apiCall.context.data[0].product_status_id,
                        description: apiCall.context.data[0].description,
                        tagId: apiCall.context.data[0].tag_id,
                        searchKeyword: apiCall.context.data[0].search_keyword
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Add Products</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Products', url: '/products'}, {label: 'Add', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <a href="/products" className="rwt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">VIEW PRODUCTS</a>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1 "> 
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-lg-5 p-md-2 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {(Notification.status) ?
                                            <div className="col-12 text-center">{Notification.message}</div> : <>
                                                <div className="col-md-12">
                                                    <div className="text-left mt-3 rwt-txt-dark-blue">
                                                        <h3><b>Product Details</b></h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 mt-3">
                                                    <label className="form-label fs-14rem">Name</label>
                                                    <input type="text" defaultValue={InputFields.name} className="form-control" name="name" {...register("name", { required: {
                                                        value: true,
                                                        message: "Name is mandatory"
                                                    }, minLength: {
                                                        value: 3,
                                                        message: "Enter atleast 3 characters"
                                                    }, maxLength: {
                                                        value: 58,
                                                        message: "Only 58 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.name && errors.name.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <div>
                                                        <label className="form-label fs-14rem">Image</label>
                                                    </div>
                                                    <div className="pt-1">
                                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => setBrowseImage(true)}>Browse Gallery</button>
                                                    </div>
                                                    {(InputFields.image)? <div><span className='text-primary fs-14rem cursor-pointer' onClick={() => setPreviewImage(`${config.assets.products}/${orgInfo.domain}/products/medium/${InputFields.image}`)}>Preview Image</span><span onClick={removeProductImage} className='ms-2 text-danger fs-14rem cursor-pointer'>Delete Image</span></div>:null}
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">MRP</label>
                                                    <input type="text" defaultValue={InputFields.mrp} className="form-control" name="mrp" {...register("mrp", { required: {
                                                        value: true,
                                                        message: "MRP is mandatory"
                                                    }, minLength: {
                                                        value: 1,
                                                        message: "Minimum 1 characters allowed"
                                                    }, maxLength: {
                                                        value: 5,
                                                        message: "Max 5 characters allowed"
                                                    }, pattern: {
                                                        value: /^[0-9+-]+$/,
                                                        message: "Enter valid MRP!"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.mrp && errors.mrp.message}
                                                    </small>
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Discount</label>
                                                    <input type="text" defaultValue={InputFields.discount} className="form-control" name="discount" {...register("discount", { required: {
                                                        value: true,
                                                        message: "Discount is mandatory"
                                                    }, minLength: {
                                                        value: 1,
                                                        message: "Minimum 1 characters allowed"
                                                    }, maxLength: {
                                                        value: 2,
                                                        message: "Max 2 characters allowed"
                                                    }, pattern: {
                                                        value: /^[0-9+-]+$/,
                                                        message: "Enter valid Discount!"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.discount && errors.discount.message}
                                                    </small>
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Status</label>
                                                    <select value={InputFields.productStatusId} className="form-control custom-select" name="productStatusId" {...register("productStatusId", { required: true })} onChange={handleStatusChange}>
                                                        <option value=""> Choose...</option>
                                                        <option value={1}> Active</option>
                                                        <option value={2}> Inactive</option> 
                                                    </select>
                                                    <small className="form-text text-danger">
                                                        {errors.productStatusId?.type === "required" && "Please update status"}
                                                    </small>
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Category</label>
                                                    <Category register={register} errors={errors} selectedValue={InputFields.categoryId||''} locationFunction={handleStateConfig} token={token} />
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Tag Must Try</label>
                                                    <Tag register={register} errors={errors} selectedValue={InputFields.tagId||''} locationFunction={handleStateConfig} token={token} />
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Search keyword</label>
                                                    <input type="text" defaultValue={InputFields.searchKeyword} className="form-control" name="searchKeyword" {...register("searchKeyword", { required: {
                                                        value: false,
                                                        message: "Keyword is mandatory"
                                                    }, minLength: {
                                                        value: 3,
                                                        message: "Minimum 3 characters allowed"
                                                    }, maxLength: {
                                                        value: 22,
                                                        message: "Max 22 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.searchKeyword && errors.searchKeyword.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-12 col-sm-12 mt-3">
                                                    <label className="form-label fs-14rem">Description</label>
                                                    <textarea defaultValue={InputFields.description} className="form-control" name="description" {...register("description", { required: {
                                                        value: false,
                                                        message: "Description is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Minimum 5 characters allowed"
                                                    }, maxLength: {
                                                        value: 65,
                                                        message: "Max 65 characters allowed"
                                                    } })}></textarea>
                                                    <small className="form-text text-danger">
                                                        {errors.description && errors.description.message}
                                                    </small>
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                </div>
                                            </>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
            {(BrowseImage)? <GalleryBox orgInfo={orgInfo} handlegalleryimagenamefn={handleProductImageName} setbrowseimage={setBrowseImage} token={token} action="selectable" />:null}
            {(PreviewImage)? <ImagePreviewer previewimage={PreviewImage} setpreviewimage={setPreviewImage} />:null}
        </>
    )
}

export default Form;