// libs
import React, {useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, } from '@fortawesome/free-solid-svg-icons';
// components 
import CommentsBox from '../comment-box';
// config & functions 
import config from '../../../config';

function TableAddMenu({domain, list, setinputfields, inputfields, setrefresh, refresh}) {// context variable --//

    const [ShowComments, setShowComments] = useState([]);

    const handleOnSelect = (id) => {
        const obj = inputfields;
        const selectedId = obj.selectedItems.slice();
        const index = selectedId.indexOf(id);
        if (index >= 0) {
            selectedId.splice(index, 1);
            obj.selectedItems = selectedId;
            setinputfields(obj);
            setrefresh(!refresh)
        } else {
            selectedId.push(id);
            obj.selectedItems = selectedId;
            setinputfields(obj);
            setrefresh(!refresh)
        }
    }

    const toggleComments = (rowId) => {
        const sliceComments = ShowComments.slice();
        const index = sliceComments.indexOf(rowId);
        if (index >= 0) {
          sliceComments.splice(index, 1);
          setShowComments(sliceComments);
        } else {
          sliceComments.push(rowId);
          setShowComments(sliceComments);
        }
    }

    useEffect(() => {
        const getKeys = Object.keys(inputfields.comments);
        if(getKeys && Array.isArray(getKeys) && getKeys.length > 0) {
            setShowComments(getKeys)
        }
    }, [])

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table fs-14rem">
                    <tbody>
                        {list.map((data, index) => {
                            let isChecked = inputfields.selectedItems.includes(String(data.id));
                            return <React.Fragment key={Math.random()}><tr htmlFor={`flex-check-checked-${index}`} className='bg-light'>
                                    <td width="25px">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={data.id} id={`flex-check-checked-${index}`} onChange={(e) => {handleOnSelect(String(data.id))}} defaultChecked={isChecked} />
                                        </div>
                                    </td>
                                    <td width="50px">{(data.image)? <img src={`${config.assets.products}/${domain}/products/thumbnail/${data.image}`} width="44px" height="44px" className="rounded-circle border border-2" /> : '-'}</td>
                                    <td>
                                        <div className="fw-bold">{data.name} {(typeof data.tag_name !== null && data.tag_name)?<span className="rounded-pill py-1 px-2 fs-11rem fw-bold bg-danger text-white">{data.tag_name}</span>:null}</div>
                                        <div className="text-secondary fs-13rem">{data.description}</div>
                                    </td>
                                    <td style={{width: '30px', textAlign: 'right'}}>
                                        <button type='button' className={`border-1 px-2 border rounded text-white ${ShowComments.includes(String(data.id))?`rwt-secondary-btn`:`rwt-primary-btn`}`} onClick={() => toggleComments(String(data.id))}>{ShowComments.includes(String(data.id))?<FontAwesomeIcon icon={faArrowUp} className="" />:<FontAwesomeIcon icon={faArrowDown} className="" />}</button>
                                    </td>
                                </tr>
                                {ShowComments.includes(String(data.id)) && (
                                    <tr key={data.id}>
                                        <td colSpan="4" style={{textAlign: 'right'}}>
                                            <CommentsBox setinputfields={setinputfields} inputfields={inputfields} setrefresh={setrefresh} refresh={refresh} rowid={data.id} />
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        })}
                    </tbody>
                </table>
            </div>
        </>
    } else {
        return null;
    }
}

export default TableAddMenu;