import React from 'react'
import config from '../config';
import ajaxcall from '../utils/ajaxcall';

function Status(props) {
    const [Status, setStatus] = React.useState(null);
    React.useEffect(() => {
        async function fetchData() {
            let apiCall = await ajaxcall.getCall(`${config.api.generic}/status`, {}, props.token);
            if(apiCall && apiCall.status) {
                setStatus(apiCall.context.data);
            }
        }
        fetchData();
    }, []); 

    const handleChange=((val)=>{ 
        props.locationFunction('statusId', val.target.value) 
    })

    if (!Status) return <div className="text-primary">Loading Status values</div>;
    return (
        <>
            <select {...props.register("statusId", { required: true })} value={props.selectedValue} className="form-control custom-select" onChange={handleChange} >
                <option value=""> Choose...</option>
                {(Status) ? Status.map((element) => { 
                    return (<option key={Math.random()} value={element.id}>{element.label}</option>)
                }) : ''}
            </select>
            <small className="form-text text-danger">
                {props.errors.statusId?.type === "required" && "Status is mandatory"}
            </small>
        </>
    )
}
export default Status;