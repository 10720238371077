// libs
import React, {useEffect, useRef, useState} from 'react'
// components 
// ... 
// config & functions 
// ...

function ConfirmationBox({rowid, inputfields, setinputfields, refresh, setrefresh, setconfirmdelete}) {

    const wrapperRef = useRef(null);
    const [IsDelete, setIsDelete] = useState(false);

    const hideModal = () => {
        setconfirmdelete(false); // make it false and remove component from parent div
	}

    const deleteComments = () => {
        setIsDelete(true);
        let getInput = inputfields;
        const oldObj = getInput.comments;
        if(oldObj[rowid]) {
            delete oldObj[rowid];
            getInput.comments = oldObj;
            setinputfields(getInput);
            setrefresh(!refresh);
            hideModal();
        }
        setIsDelete(false);
    }

    return (
        <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
			<div className="modal-dialog">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="staticBackdropLabel">Confirmation Box</h5>
					<button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
				</div>
				<div className="modal-body text-start fs-14rem">
					Are you sure you want to delete this record?
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
					<button type="button" className="btn btn-primary fs-14rem" disabled={IsDelete} onClick={() => deleteComments()}>{(IsDelete)?'Deleting...':'Delete'}</button>
				</div>
				</div>
			</div>
		</div>
    )
}
export default ConfirmationBox;
