// libs
import React, {useEffect, useState} from 'react';
import Pagination from "react-js-pagination";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components 
import TableAddMenu from './tables/add-menu';
import Loading from '../../components/loading';
import Record404 from '../../components/record404';
import SearchFilters from './search-filters';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function AddMenu({industryid, token, orgfoodtype, domain, handlemenuitemsfn, parentinputfields, setloadmenu}) {

    const [Refresh, setRefresh] = useState(false);
    const [InputFields, setInputFields] = useState({
        startDate: '',
        totalMembers: 0,
        selectedItems: [],
        comments: {}
    });

    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [ItemList, setItemList] = useState({});
    const [CurrentPaginationId, setCurrentPaginationId] = useState(0);
    const [AppliedSearchKeyword, setAppliedSearchKeyword] = useState("");
    const [AppliedCategory, setAppliedCategory] = useState("");
    const [ListSelectedItems, setListSelectedItems] = useState(false);

    const onSubmit = async (data) => {
		setDisableSubmitButton(true);
		// onSubmit, copy data to state objects...
		let fields = InputFields;
		for (const key in fields) {
			if (fields.hasOwnProperty(key) && data.hasOwnProperty(key)) {
                if(key === 'startDate') {
                    fields[key] = utils.getYYMMDD_HHMM_FromDate(data.startDate);
                } else if (key === 'selectedItems') {
                    fields[key] = fields.selectedItems;
                } else if (key === 'comments') {
                    fields[key] = fields.comments;
                } else {
                    fields[key] = data[key]; // push totalmembers key
                }
            }
		}

        if(fields.selectedItems.length > 0) {
            // Take comments only from selected items 
            let filterComments = {};
            fields.selectedItems.forEach(key => {
                console.log({[key]: fields.comments[key]})
                if(fields.comments[key]) {
                    Object.assign(filterComments, {[key]: fields.comments[key]})
                }
            });
            fields.comments = filterComments;

            if (parentinputfields.menuItemsEditIndex !== "") {
                if (parentinputfields.menuItems.some((e,i) => i !== parseInt(parentinputfields.menuItemsEditIndex) && new Date(e.startDate).getTime() === new Date(fields.startDate).getTime() && String(e.time).toLowerCase() === String(fields.time).toLowerCase())) {
                    toast.error('Venue already created with Date and Time. Please choose different venue.');
                    setDisableSubmitButton(false);
                } else {
                    setloadmenu(false);
                    handlemenuitemsfn(fields, 'update');
                } 
            } else if (parentinputfields.menuItems.some((e) => new Date(e.startDate).getTime() === new Date(fields.startDate).getTime() && String(e.time).toLowerCase() === String(fields.time).toLowerCase())) {
                toast.error('Venue already created with Date and Time. Please choose different venue.');
                setDisableSubmitButton(false);
            } else {
                setloadmenu(false);
                handlemenuitemsfn(fields, 'add');
            } 
        } else {
            setDisableSubmitButton(false);
            toast.error('Items cannot be empty. Please choose few items');
        }
    }

	const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

    const loadApiData = async (params) => {
        setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/products`, params, token);
        if(apiData && apiData.status) {
            setItemList({list: apiData.context.data.records, totalItems: apiData.context.data.totalCount});
            setIsLoading(false);
            setIs404(false);
        } else {
            setItemList({list: [], totalItems: 0});
            setIsLoading(false);
            setIs404(true);
        }
    }

    const handlePageChange = async (pageNumber) => {
        setIsLoading(true);
		let offset = (pageNumber*config.pagination.limitPerPage)-config.pagination.limitPerPage;
		loadApiData({showSelected:false, categoryId:AppliedCategory, searchKeyword:AppliedSearchKeyword, offset, limit:config.pagination.limitPerPage});
        setCurrentPaginationId(pageNumber);
	}

    const removeSearchKeyword = () => {
        setAppliedSearchKeyword("");
    }

    useEffect(() => {
        async function fetchData() {
            if(!Is404 && Object.keys(ItemList).length === 0) {
			    loadApiData({showSelected:false, categoryId:AppliedCategory, searchKeyword:AppliedSearchKeyword, offset:CurrentPaginationId, limit:config.pagination.limitPerPage});
            } else {
                setIsLoading(false);
            }
		}
		fetchData();
    }, []);

    useEffect(() => {
        // Check if edit mode..
        if(parentinputfields.menuItemsEditIndex !== "") {
            const i = parentinputfields.menuItemsEditIndex;
            const val = {
                startDate: new Date(parentinputfields.menuItems[i].startDate),
                totalMembers: parentinputfields.menuItems[i].totalMembers || 0,
                selectedItems: parentinputfields.menuItems[i].selectedItems || [],
                comments: parentinputfields.menuItems[i].comments || {}
            };
            reset(val);
            setInputFields(val);
        }
    }, []);

    useEffect(() => {
        if(!AppliedCategory) {
            // reset "Show All" filter on All category
            setListSelectedItems(false);
        }
        setCurrentPaginationId(0);
        loadApiData({showSelected:false, categoryId:AppliedCategory, searchKeyword:AppliedSearchKeyword, offset:0, limit:config.pagination.limitPerPage});
    }, [AppliedSearchKeyword, AppliedCategory]);

    useEffect(() => {
        let selected = false;
        if(ListSelectedItems) {
            selected = InputFields.selectedItems;
        }
        setCurrentPaginationId(0);
        loadApiData({showSelected:selected, categoryId:AppliedCategory, searchKeyword:AppliedSearchKeyword, offset:0, limit:config.pagination.limitPerPage});
    }, [ListSelectedItems]);

    useEffect(() => {
        // if show all & empty selected item
        if(ListSelectedItems && InputFields.selectedItems.length === 0) {
            setListSelectedItems(false)
        }
    }, [InputFields.selectedItems]);

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Add Menu</h5>
                            <button type="button" className="btn-close me-2" onClick={() => setloadmenu(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form action="" method="" className="g-3 py-3 px-lg-5 p-md-1 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-8">
                                        <label className="form-label fs-14rem">Date</label>
                                        <Controller
                                            name='startDate' 
                                            defaultValue={InputFields.startDate}
                                            rules={{required: true}}
                                            control={control} 
                                            render={({field}) => (
                                                <DatePicker 
                                                    selected={field.value} 
                                                    placeholderText="Start Date" 
                                                    showTimeSelect 
                                                    timeIntervals={15}
                                                    dateFormat="MMMM-d-yyyy, h:mm aa" 
                                                    value={field.value} 
                                                    className="form-control" 
                                                    minDate={parentinputfields.startDate} 
                                                    maxDate={parentinputfields.endDate} 
                                                    onChange={(e) => {
                                                        updateChangeEvent(e, field)
                                                    }}
                                                />
                                            )}
                                        />	
                                        <small className="form-text text-danger">
                                            {errors.startDate?.type === "required" && "Start Date is mandatory"}
                                        </small>
                                    </div>
                                    <div className="col-4">
										<label className="form-label fs-14rem">Total Members</label>
										<input type="text" defaultValue={InputFields && InputFields.totalMembers || ''} className="form-control" name="totalMembers" {...register("totalMembers", { required: {
                                            value: true,
                                            message: "Total members is mandatory"
                                        }, minLength: {
                                            value: 1,
                                            message: "Enter atleast 1 characters"
                                        }, maxLength: {
                                            value: 5,
                                            message: "Only 5 characters allowed"
                                        }, pattern: {
                                            value: /^[0-9+-]+$/,
                                            message: "Enter valid members!"
                                        } })} />
										<small className="form-text text-danger">
                                            {errors.totalMembers && errors.totalMembers.message}
										</small>
									</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mt-3">
                                            <SearchFilters industryid={industryid} token={token} orgfoodtype={orgfoodtype} setappliedsearchkeyword={setAppliedSearchKeyword} appliedsearchkeyword={AppliedSearchKeyword} setappliedcategory={setAppliedCategory} appliedcategory={AppliedCategory} />
                                        </div>
                                        <div className='d-flex flex-row justify-content-between align-items-center mb-1'>
                                            <div>
                                                {(AppliedSearchKeyword !== "") ? <button type='button' className='btn mt-2 py-1 px-2 btn-md fs-13rem text-dark bg-light border border-1 border-secondary rounded'>{AppliedSearchKeyword} <span onClick={removeSearchKeyword}>X</span></button> : null}
                                            </div>
                                            {(!AppliedCategory && InputFields.selectedItems.length >0)?<button type='button' onClick={()=>setListSelectedItems(!ListSelectedItems)} className='mt-2 bg-transprent btn p-0 rwt-txt-dark-blue fs-14rem'>{(ListSelectedItems)?'Show All':'Show Selected'}</button>:null}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="pt-2 pb-2">
                                            <div>{(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <TableAddMenu domain={domain} list={ItemList.list} setinputfields={setInputFields} inputfields={InputFields} setrefresh={setRefresh} refresh={Refresh} />}</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-flex justify-content-center">
                                            <Pagination
                                                activePage={CurrentPaginationId}
                                                itemsCountPerPage={config.pagination.limitPerPage}
                                                totalItemsCount={parseInt(ItemList.totalItems)}
                                                pageRangeDisplayed={5}
                                                onChange={(e) => handlePageChange(e)}
                                                innerClass="pagination" 
                                                itemClass="page-item" 
                                                linkClass="page-link" 
                                                activeClass="active"
                                            />
                                        </div>
                                    </div>  
                                </div>
                                <div className='row'>
                                    <div className="col-12 text-end">
                                        <button type="submit" disabled={DisableSubmitButton} className="rwt-secondary-btn text-end btn-md text-white btn mt-4 fs-14rem">{(DisableSubmitButton)?`LOADING...`:`SAVE`}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem me-2" onClick={() => setloadmenu(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}
export default AddMenu;
