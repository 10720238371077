// libs
import React, { useState } from 'react';
// components
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Breadcrumb from "../../components/breadcrumb";
import GalleryBox from '../../components/gallery-box';
// css
import "../../assets/css/style.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function Image({token, orgInfo}) {

    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [BrowseImage, setBrowseImage] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = async (data) => {
        setDisableSubmitButton(true);
        // send it to backend
        const formData = new FormData();
        formData.append("file", data.fileName[0]);
        formData.append("imgLocation", 'product');
        formData.append("projectName", "vtCateringERP");
        formData.append("fileNamePrefix", '_');
        formData.append("clientDomain", orgInfo.domain);

        let apiCall = await ajaxCall.postCall(`${config.api.image}/upload`, formData, token);
        if(apiCall && apiCall.status) {
            toast.info('File updated successfully');
            reset();
            setDisableSubmitButton(false);
        } else {
            let msg = (apiCall && apiCall.hasOwnProperty('context')) ? apiCall.context.message : "Something went wrong";
            toast.error(msg);
            setDisableSubmitButton(false);
        }
    }

	return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Upload Images</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Images', url: '/upload-image'}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <button type="button" className="rwt-primary-btn btn-md btn btn-outline-none text-white fs-14rem" onClick={() => setBrowseImage(true)}>VIEW IMAGES</button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1 ">
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-lg-5 p-md-2 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-8 mt-3">
                                            <label htmlFor="formFile" className="form-label">Choose your file. Only jpeg,png allowed</label>
                                            <input defaultValue={``} className="form-control" type="file" accept="image/png, image/jpeg" id="formFile" name="fileName" {...register("fileName", { required: {
                                                value: true,
                                                message: "File is mandatory"
                                            }, validate: {
                                                lessThan10MB: (files) => files[0]?.size < 300000 || "Please upload max 300kb file"
                                                // acceptedFormats: (files) =>
                                                //   ["image/jpeg", "image/png", "image/gif"].includes(
                                                //     files[0]?.type
                                                //   ) || "Only PNG, JPEG e GIF"
                                            } })} />
                                            <small className="form-text text-danger">
                                                {errors.fileName && errors.fileName.message}
                                            </small>
                                        </div>
                                        <div className="col-4"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{(DisableSubmitButton)?`LOADING...`:`UPLOAD`}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
            {(BrowseImage)? <GalleryBox orgInfo={orgInfo} setbrowseimage={setBrowseImage} token={token} />:null}
        </>
	)
}

export default Image;