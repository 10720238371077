import { useState } from "react";

export default function UserToken() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    
    const saveToken = (userToken) => {
        localStorage.setItem('token', userToken);
        setToken(userToken);
    }

    return {
        setToken: saveToken,
        token
    }
}