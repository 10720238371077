import React, {useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
// components 
import ConfirmationBox from './confirmation-box/comment-deletion';
// config & functions 
// ...

function CommentsBox({setinputfields, inputfields, setrefresh, refresh, rowid}) {// context variable --//
    const [Comments, setComments] = useState();
    const [ConfirmDelete, setConfirmDelete] = useState(false);
    const [RowId, setRowId] = useState('');

    const pushComments = (comments) => {
        let getInput = inputfields;
        const oldObj = getInput.comments;
        let newObj = {...oldObj, ...comments}
        getInput.comments = newObj;
        setinputfields(getInput);
        setrefresh(!refresh);
    }

    const saveComments = (rowId) => {
        if(Comments) {
            if(Comments.length <= 225) {
                let objVal = {[rowId]: Comments};
                pushComments(objVal);
                toast.success('Comments updated');
            } else {
                toast.error('Comments cannot be greated then 225 characters');
            }
        } else {
            toast.error('Comments cannot be blank');
        }
    }

    const deleteComments = (id) => {
        setRowId(String(id));
        setConfirmDelete(true);
    }

    useEffect(() => {
        setComments(inputfields.comments[rowid] || '');
    },[])

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if(Comments) {
    //             saveComments(rowid); 
    //         }
    //     }, 2000);
    
    //     // if this effect run again, because `value` changed, we remove the previous timeout
    //     return () => clearTimeout(timeout)
    // }, [Comments])

    return (<>
            <textarea className='form-control bg-white' rows="2" disabled={(inputfields.selectedItems.includes(String(rowid)))?false:true} placeholder='Select item to edit' onChange={(e) => setComments(e.target.value)} defaultValue={inputfields.comments[parseInt(rowid)] || ''}></textarea>
            <button type='button' className="btn-sm border-1 px-2 border border-white rounded btn rwt-secondary-btn text-white mt-2 me-2" onClick={() => deleteComments(String(rowid))}>Remove <FontAwesomeIcon icon={faTrash} className="" /></button>
            <button type='button' className="btn-sm border-1 px-2 border border-white rounded btn rwt-primary-btn text-white mt-2 me-3" onClick={() => saveComments(String(rowid))}>Save <FontAwesomeIcon icon={faCheck} className="" /></button>
            {(ConfirmDelete)?<ConfirmationBox rowid={RowId} inputfields={inputfields} setinputfields={setinputfields} refresh={refresh} setrefresh={setrefresh} setconfirmdelete={setConfirmDelete} />:null}
        </>
    )
}

export default CommentsBox;