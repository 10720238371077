// libs
import React, {useEffect, useRef, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
// components 
// ... 
// config & functions 
import ajaxCall from '../../../utils/ajaxcall'
import config from '../../../config';

function ConfirmationBox({setlistdata, listdata, id, url, setrowid, token}) {

    const wrapperRef = useRef(null);
    const [IsDelete, setIsDelete] = useState(false);

    const hideModal = () => {
        setrowid(0); // make it 0 and remove component from parent div
	}

    const updateList = () => {
        let list = {...listdata};
        let quote = list.quotations;
        let clients = list.clients;
        let quoteIndex = quote.findIndex(obj => obj.quotations_id === id.quotationId && obj.clients_id === id.clientId)
        const clientIndex = clients.findIndex(obj => obj.clients_id === id.clientId);
        quote.splice(quoteIndex, 1);
        list.quotations = quote;
        list.clients[clientIndex].total_quotations = parseInt(list.clients[clientIndex].total_quotations)-1;
        setlistdata(list);
        hideModal();
    }

    const initDeleteRecord = async () => {
        setIsDelete(true);
        const apiCall = await ajaxCall.deleteCall(url, {id: id.quotationId, clientId: id.clientId}, token);
        if(apiCall && apiCall.status) {
            setIsDelete(false);
            toast.info('Record deleted successfully');
            updateList();
        } else {
            setIsDelete(false);
            toast.error('Something went wrong. Please try again');
        }
    }

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Confirmation Box</h5>
                        <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                    </div>
                    <div className="modal-body fs-14rem">
                        Are you sure you want to delete this record?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary fs-14rem" disabled={IsDelete} onClick={() => initDeleteRecord()}>{(IsDelete)?'Deleting...':'Delete'}</button>
                    </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}
export default ConfirmationBox;
