// libs
import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import md5 from 'crypto-js/md5';
import { ToastContainer, toast } from 'react-toastify';
// components 
import Logo from '../../components/logo';
import '../../assets/css/core.css';
import Footer from '../../components/footer';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function Login({setToken}) {

    const [Loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const onSubmit = async (data) => { 
        setLoading(true);
        let validate = await ajaxCall.postCall(`${config.api.generic}/login`, {
            email: data.username,
            password: md5(data.password).toString()
        });
        if(validate && validate.status) {
            if(validate.context.hasOwnProperty('data') && validate.context.data.length > 0) {
                reset();
                setToken(validate.context.data[0]);
                toast.info('Login validated. Redirecting...');
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 1000);
            } else {
                toast.error('Something went wrong. Please try again'); 
                setLoading(false);   
            }
        } else {
            toast.error('Invalid credentials. Please try again');
            setLoading(false);   
        }
    }

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                        <div className="login-form bg-light mt-4 p-4">
                            <form action="" method="" className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                                <div className="text-center">
                                    <img src='/assets/images/rwlogo-h.png' style={{maxWidth: '100%'}} />
                                </div>
                                <div className="col-12">
                                    <label className="rwt-txt-dark-blue">Username</label>
                                    <input type="text" className={`form-control ${errors.username && "invalid"}`}
                                        {...register("username", { required: "Username is Required" })}
                                        onKeyUp={() => {
                                            trigger("username");
                                        }} placeholder="Username" />
                                    {errors.username && (
                                        <small className="text-danger">{errors.username.message}</small>
                                    )}
                                </div>
                                <div className="col-12">
                                    <label className="rwt-txt-dark-blue">Password</label>
                                    <input type="password" className={`form-control ${errors.password && "invalid"}`}
                                        {...register("password", { required: "Password is Required" })}
                                        onKeyUp={() => {
                                            trigger("password");
                                        }} placeholder="Password" />
                                    {errors.password && (
                                        <small className="text-danger">{errors.password.message}</small>
                                    )}
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="rememberMe" />
                                        <label className="form-check-label rwt-txt-dark-blue" htmlFor="rememberMe"> Remember me</label>
                                    </div>
                                </div>
                                <div className="col-12 d-grid gap-2 col-6 mx-auto">
                                    <button type="submit" disabled={(Loading)?true:false} className="rwt-primary-btn btn-sm btn pb-2">{(Loading)?'Loading...':'Login'}</button>
                                </div>
                            </form>
                            {/* <hr className="mt-4" />
                            <div className="col-12">
                                <p className="text-center mb-0 rwt-txt-dark-blue fs-14rem">Forgot your Password? <a href="/forgot-password" className="foot-link rwt-txt-dark-blue"> Reset Password</a></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="fixed-bottom"><Footer /></div>
        </div>
    )
}

export default Login;
