// libs
import React, {useEffect, useRef, useState} from 'react'
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
// components 
import Loading from "./loading";
import Record404 from './record404';
// config & functions 
import ajaxCall from '../utils/ajaxcall'
import config from '../config';
import utils from '../utils';

function GalleryBox({orgInfo, handlegalleryimagenamefn, setbrowseimage, selectedimg, token, action='view'}) {
    const wrapperRef = useRef(null);
    const [SelectedProduct, setSelectedProduct] = useState(selectedimg);
    const [IsLoading, setIsLoading] = useState(false);
    const [LoadMoreImg, setLoadMoreImg] = useState(false);
    const [ImgList, setImgList] = useState({nextToken: '', data: ''});
    const [Is404, setIs404] = useState(false);

    const showModal = () => {
        const modalEle = wrapperRef.current
        const bsModal = new Modal(modalEle, {
            backdrop: false,
            keyboard: false
        })
        bsModal.show()
    }

    const hideModal = () => {
		const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setbrowseimage(false); // remove component from parent div
	}

    const getImages = async () => {
        setIsLoading(true);
        let apiCall = await ajaxCall.getCall(`${config.api.image}/files`, {projectName: 'vtCateringERP', imgLocation: 'product', clientDomain: orgInfo.domain}, token);
        if(apiCall && apiCall.status) {
            setImgList(apiCall.context.data);
            setIsLoading(false);
            setIs404(false);
        } else {
            toast.error('Something went wrong. Please try again :(');
            setIsLoading(false);
            setIs404(true);
        }
    }

    const ListImages = ({list}) => {
        if(Array.isArray(list) && list.length > 0) {
            return <div className="container" key={Math.random()}>
                <div className="row row-cols-6">
                    {list.map((d,i) => {
                        let fname = d.name.substring(d.name.lastIndexOf('/')+1)
                        let selected = false;
                        if(SelectedProduct && SelectedProduct.split(',').indexOf(fname) !== -1) {
                            selected = true;
                        }
                        return <div className="col" key={Math.random()}>
                            {(action === "selectable") ? <div className={(selected)?'border border-dark border-2':''} onClick={(e) => chooseSelection(d.name)}><img className="py-2" src={`${d.url}`} width="100%" /></div> : <a target="_blank" href={`${d.url}`}><img className="py-2" src={`${d.url}`} width="100%" /></a>}
                        </div>
                    })}
                </div>
            </div>
        } else {
            return null;
        }
    }

    const saveSelection = () => {
        const pString = SelectedProduct;
        handlegalleryimagenamefn(pString);
        hideModal();
    }

    const chooseSelection = async (img) => {
        let fname = await utils.fileNameFromPath(img);
        // if available, remove it else add 
        if(SelectedProduct && SelectedProduct.split(',').indexOf(fname) !== -1) {
            let arr = SelectedProduct.split(',');
            let index = arr.indexOf(fname);
            arr.splice(index,1);
            setSelectedProduct(arr.join())
        } else {
            let fname = await utils.fileNameFromPath(img);
            let sp = SelectedProduct;
            let newProduct = fname;
            if(sp) {
                newProduct = `${sp},${fname}`
            }
            setSelectedProduct(newProduct)
        }
    }

    const loadMore = async (nextToken) => {
        setLoadMoreImg(true);
        let apiCall = await ajaxCall.getCall(`${config.api.image}/files`, {projectName: 'rwCateringERP', imgLocation: 'product', clientDomain: orgInfo.domain, nextToken: nextToken}, token);
        if(apiCall && apiCall.status) {
            if(apiCall.context.data.hasOwnProperty('data') && apiCall.context.data.data.length > 0) {
                let records = ImgList;
                records.nextToken = (apiCall.context.data.hasOwnProperty('nextToken') && apiCall.context.data.nextToken !== '')?apiCall.context.data.nextToken:"";
                records.data = [...records.data, ...apiCall.context.data.data];
                setImgList(records);
                setLoadMoreImg(false);
            } else {
                let records = ImgList;
                records.nextToken = "";
                setImgList(records);
                setLoadMoreImg(false);
            }
        }
    }

    const LoadMoreBtn = ({token}) => {
        return <div className="text-center py-3"><button type="button" disabled={(LoadMoreImg)?true:false} className="btn btn-primary btn-sm fs-14rem" onClick={() => loadMore(token)}>{(LoadMoreImg)?'Loading...':'Load More'}</button></div>
    }

    useEffect(() => {
        async function loadComponent() {
            setSelectedProduct(selectedimg);
            showModal();
            getImages();
        }
        loadComponent();
    },[]);

    return (
        <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
			<div className="modal-dialog modal-fullscreen">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="staticBackdropLabel">Browse Gallery</h5>
					<button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
				</div>
				<div className="modal-body">
					{(IsLoading) ? <Loading /> : null}
                    {(ImgList) ? <ListImages list={ImgList.hasOwnProperty('data')?ImgList.data:[]} /> : null}
                    {(Is404) ? <Record404 /> : null}
                    {(ImgList && ImgList.hasOwnProperty('nextToken') && ImgList.nextToken !== '') ? <LoadMoreBtn token={ImgList.nextToken} /> : null}
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                    {(action === 'view') ? null:<button type="button" className="btn btn-primary fs-14rem" disabled={(SelectedProduct)?false:true} onClick={() => saveSelection()}>Save</button>}
				</div>
				</div>
			</div>
		</div>
    )
}
export default GalleryBox;
