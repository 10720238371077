// libs
import React, { useState, useEffect } from 'react';
// components
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Breadcrumb from "../../components/breadcrumb";
// css
import "../../assets/css/style.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function Settings({token, orgInfo}) {
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [ShowProductPrice, setShowProductPrice] = useState(false);
    const [DiscountOnProducts, setDiscountOnProducts] = useState(false);
    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleProductPrice = () => {
        setShowProductPrice(!ShowProductPrice);
    }
    const handleProductDiscount = () => {
        setDiscountOnProducts(!DiscountOnProducts);
    }

    const onSubmit = async () => {
        setDisableSubmitButton(true);
        // send it to backend
        const formData = new FormData();
        formData.append("displayPrice", Number(ShowProductPrice));
        formData.append("displayDiscount", Number(DiscountOnProducts));

        let apiCall = await ajaxCall.postCall(`${config.api.generic}/permissions/product-status`, formData, token);
        if(apiCall && apiCall.status) {
            toast.info('Permissions updated successfully');
            setDisableSubmitButton(false);
        } else {
            let msg = (apiCall && apiCall.hasOwnProperty('context')) ? apiCall.context.message : "Something went wrong";
            toast.error(msg);
            setDisableSubmitButton(false);
        }
    }

    useEffect(() => {
        async function fetchData() {
            let apiCall = await ajaxCall.getCall(`${config.api.generic}/permissions/product-status`, {}, token);
            if(apiCall && apiCall.status) {
                setShowProductPrice(Boolean(parseInt(apiCall.context.data[0].product_price)));
                setDiscountOnProducts(Boolean(parseInt(apiCall.context.data[0].product_discount)));
            }
        }
        fetchData();
    }, []);

	return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Settings</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Settings', url: ''}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1 ">
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-lg-5 p-md-2 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="text-left mt-3 rwt-txt-dark-blue">
                                                <h3><b>Product Settings</b></h3>
                                            </div>
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="productPrices" checked={ShowProductPrice} onChange={handleProductPrice} />
                                                <label className="form-check-label" htmlFor="productPrices">Show product price</label>
                                            </div>
                                            <div className="form-check form-switch mt-2">
                                                <input className="form-check-input" type="checkbox" id="productDiscounts" checked={DiscountOnProducts} onChange={handleProductDiscount} />
                                                <label className="form-check-label" htmlFor="productDiscounts">Show discount on products</label>
                                            </div>
                                        </div>
                                        <div className="col-4"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{(DisableSubmitButton)?`LOADING...`:`SAVE`}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
        </>
	)
}

export default Settings;