// libs
import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faEdit, faTrash, faLowVision, faTablet, faCheck, faCancel } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
// components 
import ConfirmationBox from '../confirmation-box/quotation-deletion';
import Preview from '../preview';
import LoadingBox from "../../../components/loading-box";
// config & functions 
import config from '../../../config';
import ajaxCall from '../../../utils/ajaxcall';

function TableList({setlistdata, listdata, domain, token}) {
    const [ShowLoadingBox, setShowLoadingBox] = useState(false);
    const [RowId, setRowId] = useState(0);
    const [ShowPreview, setShowPreview] = useState(false);
    const [PreviewData, setPreviewData] = useState([]);
    const [DetailsShown, setDetailsShown] = useState([]);
    const deleteUrl = `${config.api.generic}/quotation`;

    const toggleShown = rowId => {
        const shownState = DetailsShown.slice();
        const index = shownState.indexOf(rowId);
        if (index >= 0) {
          shownState.splice(index, 1);
          setDetailsShown(shownState);
        } else {
          shownState.push(rowId);
          setDetailsShown(shownState);
        }
    }

    const generatePreview = async (qId) => {
        setShowLoadingBox(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/static/pdf`, {quotationId: qId}, token);
        if(apiData && apiData.status && apiData.context.hasOwnProperty('data')) {
            if(Object.keys(apiData.context.data).length > 0) {
                setPreviewData(apiData.context.data);
                setShowLoadingBox(false);
                setShowPreview(true);
            } else {
                toast.error('Something went wrong. Please try again');
                setShowLoadingBox(false);
            }
        } else {
            toast.error('Something went wrong. Please try again');
            setShowLoadingBox(false);
        }
    }

    if(listdata && listdata.hasOwnProperty('clients') && Array.isArray(listdata.clients) && listdata.clients.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table fs-14rem">
                    <thead>
                        <tr>
                            <th>Client Name</th>
                            <th>Phone No</th>
                            <th>Location</th>
                            <th>Quotations</th>
                            <th>Generated By</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listdata.clients.map(data => {
                            return <React.Fragment key={Math.random()}><tr className='bg-light'>
                                    <td style={{minWidth: '160px'}}>{data.first_name} {data.last_name}</td>
                                    <td>{data.phone_no}</td>
                                    <td style={{minWidth: '320px'}}>{data.address} {data.city_label} {data.state_label}</td>
                                    <td>{data.total_quotations}</td>
                                    <td style={{minWidth: '120px'}}>{data.generated_by}</td>
                                    <td style={{minWidth: '120px'}}>
                                        <button className="border-1 px-2 border rwt-border-blue rounded bg-white" onClick={() => toggleShown(data.clients_id)}>Action {DetailsShown.includes(data.clients_id)?<FontAwesomeIcon icon={faArrowUp} className="" />:<FontAwesomeIcon icon={faArrowDown} className="" />}</button>
                                    </td>
                                </tr>
                                {DetailsShown.includes(data.clients_id) && (
                                    <tr className='bg-white'>
                                        <td colSpan="7" className='py-2'>
                                            <ul className='list-group list-group-flush'>
                                                {(listdata.hasOwnProperty('quotations') && Array.isArray(listdata.quotations) && listdata.quotations.length > 0)? listdata.quotations.map((q, i) => {
                                                    if(q.clients_id === data.clients_id) {
                                                        return <li key={Math.random()} className='list-group-item'>
                                                            <span><span className='fw-bold text-dark'>{q.reference_name} | </span> Last change by <span className='fw-bold text-dark'><u>{q.generated_by}</u></span> {q.date_time}</span>
                                                            <button onClick={() => generatePreview(q.quotations_id)} className="bg-white border-0 ms-2 p-2 text-decoration-none border-none bg-transparent rwt-txt-blue">View <FontAwesomeIcon icon={faTablet} className="dashboard-card-icon rwt-txt-blue" /></button>
                                                            <a href={`/quotation/edit/${q.clients_id}/${q.quotations_id}`} className="ms-2 p-2 text-decoration-none border-none bg-transparent rwt-txt-blue">Edit <FontAwesomeIcon icon={faEdit} className="dashboard-card-icon rwt-txt-blue" /></a>
                                                            <button onClick={() => setRowId({clientId: q.clients_id, quotationId: q.quotations_id})} className="bg-white border-0 ms-2 p-2 text-decoration-none border-none bg-transparent text-danger">Delete <FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></button>
                                                            {(parseInt(q.quotation_status_id) === 1)?<span className='ms-2 text-secondary fw-bold'>Approved <FontAwesomeIcon icon={faCheck} className="dashboard-card-icon text-success" /></span>:(parseInt(q.quotation_status_id) === 2)?<span className='ms-2 text-success fw-bold'>Not Approved <FontAwesomeIcon icon={faCancel} className="dashboard-card-icon text-secondary" /></span>:<span className='ms-2 text-primary fw-bold'>Hidden <FontAwesomeIcon icon={faLowVision} className="dashboard-card-icon text-primary" /></span>}
                                                        </li>
                                                    }
                                                }):<li key={Math.random()} className='list-group-item'>No records available</li>}
                                            </ul>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        })}
                    </tbody>
                </table>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {(ShowPreview)? <Preview data={PreviewData} domain={domain} setshowpreview={setShowPreview} />:null}
            {(RowId && Object.keys(RowId).length > 0) ? <ConfirmationBox setlistdata={setlistdata} listdata={listdata} url={deleteUrl} id={RowId} setrowid={setRowId} token={token} /> : null}
            {(ShowLoadingBox)? <LoadingBox />:null}
        </>
    } else {
        return null;
    }
}

export default TableList;