// libs
import React, {useEffect, useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faCheck } from '@fortawesome/free-solid-svg-icons';
// components 
import ListAllCategory from './list-all-category';
// config & functions 
import ajaxcall from '../../utils/ajaxcall';
import config from '../../config';

function SearchFilters({industryid, token, orgfoodtype, appliedsearchkeyword, setappliedsearchkeyword, setappliedcategory, appliedcategory}) {
    const searchBoxRef = useRef();
    const [Loading, setLoading] = useState(false);
    const [FoodType, setFoodType] = useState(false);
    const [CategoryList, setCategoryList] = useState([]);
    const [ShowAllCategories, setShowAllCategories] = useState(false);
    const loadApiData = async (params = {}) => {
        setLoading(true);
        let apiData = await ajaxcall.getCall(`${config.api.generic}/category`, params, token);
        if(apiData && apiData.status) {
            setLoading(false);
            setCategoryList(apiData.context.data);
        } else {
            setLoading(false);
            setCategoryList([]);
        }
    }

    const noSearch = () => {}
    const orgTypeArr =  orgfoodtype?orgfoodtype.split(','):[];
    let isOnlyVeg = false;
    if(industryid > 2) {
        // if industry apart from catering and resto, don't show veg icon - 1 & 2 are resto and catering industry
        isOnlyVeg = false;
    } else if(orgTypeArr.length === 1 && orgTypeArr.indexOf('1') >= 0) {
        isOnlyVeg = true;
    }

    const Options = () => {
        let sc = "All";
        CategoryList.map((element) => { 
            if(parseInt(appliedcategory)===parseInt(element.id)) {
                sc = element.label;
            }
        })
        return <li role="presentation">
                {isOnlyVeg?<span className="me-3">
                    <FontAwesomeIcon icon={faCheck} className="text-success" /> <strong className="text-success">Pure Veg <img src="/assets/images/pure-veg.jpg" style={{height: '20px'}} /></strong>
                </span>:null} Showing for: <strong>{sc}</strong> Category
            </li>
    }

    useEffect(() => {
        async function fetchData() {
            loadApiData({foodType: FoodType});
		}
		fetchData();
    }, [FoodType]);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-5 mt-2 offset-md-6 offset-lg-7">
                    <div className="input-group input-group-sm">
                        <input type="text" defaultValue={appliedsearchkeyword} ref={searchBoxRef} className="form-control" name="searchKeyword" aria-label="Search keywords" aria-describedby="search-button" placeholder="Search keywords" />
                        <button type="button" id="search-button" className="rwt-primary-btn text-white btn" onClick={() => {(searchBoxRef.current.value)?setappliedsearchkeyword(searchBoxRef.current.value):noSearch()}} >SEARCH</button>
                    </div>
                </div>      
                <div className="col-12 col-lg-12 mt-3">
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                        <ul className="custom-tabs mb-0" role="tablist">
                            <Options />
                        </ul>
                        <button type="button" onClick={()=>setShowAllCategories(true)} disabled={Loading?true:false} className="btn btn-sm bg-light border rounded-0 border-1 border-gray py-1 fs-14rem" style={{whiteSpace: 'pre'}}><FontAwesomeIcon icon={faList} className="" /> {Loading?'Loading':'Show Categories'}</button>
                    </div>
                </div>
            </div>
            {ShowAllCategories?<ListAllCategory industryid={industryid} loading={Loading} isonlyveg={isOnlyVeg} foodtype={FoodType} setfoodtype={setFoodType} predata={CategoryList} appliedcategory={appliedcategory} setappliedcategory={setappliedcategory} setshowallcategories={setShowAllCategories} />:null}
        </>
    )
}
export default SearchFilters;
