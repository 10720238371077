import React from 'react'

function Footer() {
    return (
        <> <footer className="bg-light text-center border-top text-lg-start p-3">
            <div className="text-center rwt-txt-dark-blue fs-12rem">
                &copy; 2023, A product by 
                <a href="https://rapidwildtechnologies.com/" className="foot-link rwt-txt-dark-blue text-decoration-none fw-bold"> RapidWild Technologies LLP</a>
            </div>
            <div className="text-center rwt-txt-dark-blue fs-12rem">
                For queries please email us on: 
                <a href="mailto:info@rapidwildtech.com" className="foot-link rwt-txt-dark-blue text-decoration-none"> info@rapidwildtech.com</a>
            </div>
        </footer>
        </>
    )
}

export default Footer;
