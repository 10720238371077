export default {
    api: {
        generic: "https://qrmenu.api.vyayatatech.com/public",
        // generic: "http://localhost:8001/public",
        image: "https://fileoperations-dot-inhouse-all-services-420412.el.r.appspot.com",
        pdfExport: "https://qrmenu.download.vyayatatech.com/public"
        // pdfExport: "http://localhost:8001/public"
    },
    assets: {
        products:"https://storage.googleapis.com/inhouse-catering-app-public",
        generic:"assets/generic"
    },
    publicWeb: "https://qrmenu.web.vyayatatech.com",
    secretKey: "123456789",
    pagination: {
        limitPerPage: 15
    }
}