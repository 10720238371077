// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
// components 
// ...
// config & functions 
import utils from '../../utils';

function AllPayments({predata, handleallpayments, setloadallpayments}) {

    const [DataList, setDataList] = useState([]);
    const [Refresh, setRefresh] = useState(false);
    const [TotalPrice, setTotalPrice] = useState(0);

    const save = () => {
        handleallpayments({
            list: DataList,
            total: TotalPrice
        });
        setloadallpayments(false);
    }

    const updateData = (label, value, i) => {
        let d = DataList;
        d[i][label] = value;
        if(label === 'price') {
            let t = d.map(item => item.price).reduce((prev, next) => parseInt(prev) + parseInt(next));
            setTotalPrice(t);
        }
        setDataList(d);
        setRefresh(!Refresh);
    }

    const removeRow = (i) => {
        let d = DataList;
        d.splice(i, 1);
        if(d.length > 0) {
            let t = d.map(item => item.price).reduce((prev, next) => parseInt(prev) + parseInt(next));
            setTotalPrice(t);
        } else {
            setTotalPrice(0);
        }
        setDataList(d);
        setRefresh(!Refresh);
    }

    const addRow = () => {
        let d = DataList;
        d.push({
            key: Math.random(),
            label: "",
            price: 0,
            date: ""
        });
        setDataList(d);
        setRefresh(!Refresh);
    }

    useEffect(() => {
        if(predata && Array.isArray(predata) && predata.length > 0) {
            setDataList(predata);
            let t = predata.map(item => item.price).reduce((prev, next) => parseInt(prev) + parseInt(next));
            setTotalPrice(t);
        } else {
            setDataList([{
                key: Math.random(),
                label: "",
                price: 0,
                date: ""
            }])
        }
    }, []);

    const printTotal = utils.numberWithCommas(TotalPrice);
    console.log(DataList)
    
    return (
        <>
            <div className="modal fade show modal-xl" data-dismiss="modal" tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">All Payments</h5>
                        <button type="button" className="btn-close" onClick={()=>setloadallpayments(false)} aria-label="Close"></button>
                    </div>
                    <div className="modal-body fs-14rem">
                        <div className="col-md-12">
                            {DataList.map((ele, i) => {
                                return <div className="row mb-2" key={`spkey-${ele.key}`}>
                                    <div className="col">
                                        <div className="row mb-2">
                                            <div className="col">
                                                {i===0?<label className='form-label fs-14rem'>Label</label>:null}
                                                <div className="d-flex">
                                                    <input type="text" className="form-control" placeholder={`Installment ${i}`} defaultValue={ele.label} onChange={(e) => updateData('label', e.target.value, i)} />
                                                </div>
                                            </div>
                                            <div className="col">
                                                {i===0?<label className='form-label fs-14rem'>Price</label>:null}
                                                <input type="text" className="form-control" placeholder="30000" defaultValue={ele.price} onChange={(e) => updateData('price', e.target.value, i)} />
                                            </div>
                                            <div className="col">
                                                {i===0?<label className='form-label fs-14rem'>Date</label>:null}
                                                <div className="d-flex">
                                                    <input type="text" className="form-control" placeholder="Date" defaultValue={ele.date} onChange={(e) => updateData('date', e.target.value, i)} />
                                                    {(DataList.length>1)?<button type="button" className="bg-white border-0 fs-24rem ms-2 p-0" onClick={(e) => removeRow(i)}><FontAwesomeIcon icon={faMinusCircle} className="rwt-txt-orange" /></button>:null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                            <div className="row">
                                <div className="col text-center">
                                    <button type="button" className="bg-white border-0 fs-24rem ms-2 p-0" onClick={addRow}><FontAwesomeIcon icon={faPlusCircle} className="rwt-txt-blue" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col">
                                    <div className="mt-3">
                                        <span className="fs-18rem fw-bold">Grand Total: </span><span className="fs-18rem fw-bold rwt-txt-orange">{printTotal}/-</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="rwt-primary-btn btn btn-outline-none text-white fs-14rem" onClick={e => save()}>SAVE</button>
                        <button type="button" className="btn btn-secondary fs-14rem" onClick={()=>setloadallpayments(false)}>Close</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AllPayments;
