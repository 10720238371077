import React from 'react'
import config from '../config';
import ajaxcall from '../utils/ajaxcall';

function Category(props) {
    const [Category, setCategory] = React.useState(null);
    React.useEffect(() => {
        async function fetchData() {
            let apiCall = await ajaxcall.getCall(`${config.api.generic}/category`, {}, props.token);
            if(apiCall && apiCall.status) {
                setCategory(apiCall.context.data);
            }
        }
        fetchData();
    }, []); 

    const handleChange=((val)=>{ 
        props.locationFunction('categoryId', val.target.value) 
    })

    if (!Category) return <div className="text-primary">Loading Category values</div>;
    return (
        <>
            <select {...props.register("categoryId", { required: true })} value={props.selectedValue} className="form-control custom-select" onChange={handleChange} >
                <option value=""> Choose...</option>
                {(Category) ? Category.map((element) => { 
                    return (<option key={Math.random()} value={element.id}>{element.label}</option>)
                }) : ''}
            </select>
            <small className="form-text text-danger">
                {props.errors.categoryId?.type === "required" && "Category is mandatory"}
            </small>
        </>
    )
}
export default Category;