// libs
import React, { useState } from 'react';
// components
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Breadcrumb from "../../components/breadcrumb";
import GalleryBox from '../../components/gallery-box';
import ImagePreviewer from '../../components/image-previewer';
// css
import "../../assets/css/style.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function HomePage({token, orgInfo}) {

    const [Notification, setNotification] = useState({ status: true, message: 'Please wait while we fetch your record' })
    const [ImgIndex, setImgIndex] = useState("");
    const [InputFields, setInputFields] = useState({
        banners: [{
            image: "",
            title: "",
            subTitle: ""
        }, {
            image: "",
            title: "",
            subTitle: ""
        }, {
            image: "",
            title: "",
            subTitle: ""
        }],
        intro: {
            title: "",
            subTitle: "",
            description: ""
        }    
    });

    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [PreviewImage, setPreviewImage] = useState('');
    const [BrowseImage, setBrowseImage] = useState(false);
    const [Refresh, setRefresh] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const removeImage = (index) => {
        let obj = InputFields;
        if(index !== "") {
            obj.banners[index].image = "";
            setInputFields(obj);
            setRefresh(!Refresh);
        } else {
            toast.error("Something went wrong");
        }
    }

    const updateImage = (position) => {
        setImgIndex(position);
        setBrowseImage(true);
    }

    const handleGalleryImageName=((val)=>{ 
        let obj = InputFields;
        if(ImgIndex !== "") {
            obj.banners[ImgIndex].image = val;
            setInputFields(obj);
        } else {
            toast.error("Something went wrong");
        }
    })

    const onSubmit = async (data) => {
        setDisableSubmitButton(true);
        const bConfig = {
            banners: [{
                image: InputFields.banners[0].image,
                title: data.banner1Title,
                subTitle: data.banner1SubTitle
            }, {
                image: InputFields.banners[1]?.image || '',
                title: data.banner2Title,
                subTitle: data.banner2SubTitle
            }, {
                image: InputFields.banners[2]?.image || '',
                title: data.banner3Title,
                subTitle: data.banner3SubTitle
            }],
            intro: {
                title: data.introTitle,
                subTitle: data.introSubTitle,
                description: data.introDescription
            }    
        }
        
        if(bConfig.banners[0].image === "") {
            toast.error('Please upload banner image for slot 1');
        } else if((bConfig.banners[1].title || bConfig.banners[1].subTitle) !== "" && bConfig.banners[1].image === "") {
            toast.error('Please upload banner image for slot 2');
        } else if((bConfig.banners[2].title || bConfig.banners[2].subTitle) !== "" && bConfig.banners[2].image === "") {
            toast.error('Please upload banner image for slot 3');
        } else {
            let apiCall = await ajaxCall.patchCall(`${config.api.generic}/account/homepage`, {
                config: JSON.stringify(bConfig)
            }, token);
            if(apiCall && apiCall.status) {
                toast.info('Record updated successfully');
            } else {
                toast.error('Something went wrong. Please try again :(');
            }
        }
        setDisableSubmitButton(false);
    }

    // Get data from DB - Onload
    React.useEffect(() => {
        async function fetchData() {
            let apiCall = await ajaxCall.getCall(`${config.api.generic}/public/account/homepage`, '', token);
            if(apiCall && apiCall.status) {
                if(apiCall.context.data[0]?.homepage_config && apiCall.context.data[0].homepage_config !== "") {
                    setInputFields(JSON.parse(apiCall.context.data[0].homepage_config));
                }
                setNotification({ status: false, message: '' })
            } else {
                setNotification({ status: false, message: '' })
            }
        }
        fetchData();
    }, []);

    console.log('InputFields... ', InputFields)

	return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Home Page</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Home Page', url: '/home-page'}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center"></div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1 ">
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-lg-5 p-md-2 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {(Notification.status) ? <div className="col-12 text-center">{Notification.message}</div> : <>
                                            <div className="col-md-12">
                                                <div className="text-left mt-3 rwt-txt-dark-blue">
                                                    <h3><b>Home Page Settings</b></h3>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div><label htmlFor="formFile-b1" className="form-label fs-14rem">Banner-1. Only jpeg,png allowed</label></div>
                                                <div className="pt-1">
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => updateImage(0)}>Browse Gallery</button>
                                                </div>
                                                {(InputFields.banners[0].image)? <div><span className='text-primary fs-14rem cursor-pointer' onClick={() => setPreviewImage(`${config.assets.products}/${orgInfo.domain}/products/medium/${InputFields.banners[0].image}`)}>Preview Logo</span><span onClick={() => removeImage(0)} className='ms-2 text-danger fs-14rem cursor-pointer'>Delete Logo</span></div>:null}
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label className="form-label fs-14rem">Banner-1 Title</label>
                                                <input type="text" defaultValue={InputFields.banners[0].title} className="form-control" name="banner1Title" {...register("banner1Title", { required: {
                                                    value: false,
                                                    message: "Banner 1 Title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.banner1Title && errors.banner1Title.message}
                                                </small>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <label className="form-label fs-14rem">Banner-1 Sub Title</label>
                                                <input type="text" defaultValue={InputFields.banners[0].subTitle} className="form-control" name="banner1SubTitle" {...register("banner1SubTitle", { required: {
                                                    value: false,
                                                    message: "Banner 1 Sub Title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.banner1SubTitle && errors.banner1SubTitle.message}
                                                </small>
                                            </div>
                                            <div className='col-12'><hr /></div>
                                            <div className="col-md-6 mt-3">
                                                <div><label htmlFor="formFile-b1" className="form-label fs-14rem">Banner-2. Only jpeg,png allowed</label></div>
                                                <div className="pt-1">
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => updateImage(1)}>Browse Gallery</button>
                                                </div>
                                                {(InputFields.banners[1]?.image)? <div><span className='text-primary fs-14rem cursor-pointer' onClick={() => setPreviewImage(`${config.assets.products}/${orgInfo.domain}/products/medium/${InputFields.banners[1].image}`)}>Preview Logo</span><span onClick={()=>removeImage(1)} className='ms-2 text-danger fs-14rem cursor-pointer'>Delete Logo</span></div>:null}
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label className="form-label fs-14rem">Banner-2 Title</label>
                                                <input type="text" defaultValue={InputFields.banners[1]?.title || ''} className="form-control" name="banner2Title" {...register("banner2Title", { required: {
                                                    value: false,
                                                    message: "Banner 2 Title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.banner2Title && errors.banner2Title.message}
                                                </small>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <label className="form-label fs-14rem">Banner-2 Sub Title</label>
                                                <input type="text" defaultValue={InputFields.banners[1]?.subTitle || ''} className="form-control" name="banner2SubTitle" {...register("banner2SubTitle", { required: {
                                                    value: false,
                                                    message: "Banner 2 Sub Title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.banner2SubTitle && errors.banner2SubTitle.message}
                                                </small>
                                            </div>
                                            <div className='col-12'><hr /></div>
                                            <div className="col-md-6 mt-3">
                                                <div><label htmlFor="formFile-b1" className="form-label fs-14rem">Banner-3. Only jpeg,png allowed</label></div>
                                                <div className="pt-1">
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => updateImage(2)}>Browse Gallery</button>
                                                </div>
                                                {(InputFields.banners[2]?.image)? <div><span className='text-primary fs-14rem cursor-pointer' onClick={() => setPreviewImage(`${config.assets.products}/${orgInfo.domain}/products/medium/${InputFields.banners[2].image}`)}>Preview Logo</span><span onClick={()=>removeImage(2)} className='ms-2 text-danger fs-14rem cursor-pointer'>Delete Logo</span></div>:null}
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label className="form-label fs-14rem">Banner-3 Title</label>
                                                <input type="text" defaultValue={InputFields.banners[2]?.title || ''} className="form-control" name="banner3Title" {...register("banner3Title", { required: {
                                                    value: false,
                                                    message: "Banner 3 Title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.banner3Title && errors.banner3Title.message}
                                                </small>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <label className="form-label fs-14rem">Banner-3 Sub Title</label>
                                                <input type="text" defaultValue={InputFields.banners[2]?.subTitle || ''} className="form-control" name="banner3SubTitle" {...register("banner3SubTitle", { required: {
                                                    value: false,
                                                    message: "Banner 3 Sub Title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.banner3SubTitle && errors.banner3SubTitle.message}
                                                </small>
                                            </div>
                                            <div className='col-12'><hr /></div>
                                            <div className="col-md-6 mt-3">
                                                <label className="form-label fs-14rem">Main Title</label>
                                                <input type="text" defaultValue={InputFields.intro?.title || ''} className="form-control" name="introTitle" {...register("introTitle", { required: {
                                                    value: true,
                                                    message: "Main title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.introTitle && errors.introTitle.message}
                                                </small>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label className="form-label fs-14rem">Main Sub Title</label>
                                                <input type="text" defaultValue={InputFields.intro?.subTitle || ''} className="form-control" name="introSubTitle" {...register("introSubTitle", { required: {
                                                    value: true,
                                                    message: "Sub title is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 125,
                                                    message: "Max 125 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.introSubTitle && errors.introSubTitle.message}
                                                </small>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <label className="form-label fs-14rem">Main Description</label>
                                                <input type="text" defaultValue={InputFields.intro?.description} className="form-control" name="introDescription" {...register("introDescription", { required: {
                                                    value: true,
                                                    message: "Description is mandatory"
                                                }, minLength: {
                                                    value: 5,
                                                    message: "Minimum 5 characters allowed"
                                                }, maxLength: {
                                                    value: 500,
                                                    message: "Max 500 characters allowed"
                                                } })} />
                                                <small className="form-text text-danger">
                                                    {errors.introDescription && errors.introDescription.message}
                                                </small>
                                            </div>
                                            <div className="col-12 text-end">
                                                <button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{(DisableSubmitButton)?`LOADING...`:`SUBMIT`}</button>
                                                {(PreviewImage)? <ImagePreviewer previewimage={PreviewImage} setpreviewimage={setPreviewImage} />:null}
                                            </div>
                                        </>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
            {(BrowseImage)? <GalleryBox orgInfo={orgInfo} handlegalleryimagenamefn={handleGalleryImageName} setbrowseimage={setBrowseImage} selectedimg="" token={token} action="selectable" />:null}
        </>
	)
}

export default HomePage;