import React from 'react'
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/css/style.css";
import Card from "./card";
import config from '../../config';

function Dashboard({token, orgInfo}) {
	const industryId = (orgInfo && orgInfo.hasOwnProperty('industryId')) ? parseInt(orgInfo.industryId) : null;
	const copyContent = async () => {
		try {
			await navigator.clipboard.writeText(`${config.publicWeb}/${orgInfo.domain}`);
			toast.success('URL copied to clipboard');
		} catch (err) {
			toast.error('Failed to copy URL: ', err);
		}
	}
	
	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row mb-4 mt-5">
					{(industryId === 2)? <div className="col-xl-3 col-6">
						<Card token={token} title="Clients" subtitle="Total Clients" apipath="total-clients" params={{status: 'active'}} prefix="" />
					</div>:null}
					<div className="col-xl-3 col-6">
						<Card token={token} title="Products" subtitle="Total Products" apipath="total-products" params="" prefix="" />
					</div>
					{(industryId === 2)? <div className="col-xl-3 col-6">
						<Card token={token} title="Quotations" subtitle="Total Quotations" apipath="total-quotations" params={{status: 'inactive'}} prefix="" />
					</div>:null}
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="text-center pt-3 pb-4">
							<button type='button' className="btn btn-lg rwt-primary-btn" onClick={copyContent}>Copy URL</button>
							<p className="fs-14rem mt-2">Share with your Clients</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
		</>
	)
}

export default Dashboard;