// libs
import React, { useState } from 'react';
// components
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Breadcrumb from "../../components/breadcrumb";
import State from "../../components/state";
import City from '../../components/city';
import Status from '../../components/status';
// css
import "../../assets/css/form.css";
import "react-datepicker/dist/react-datepicker.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";

function Client({token, orgInfo}) {
    // Temp added, need to validate properly
    if(orgInfo && orgInfo.hasOwnProperty('industryId') && parseInt(orgInfo.industryId) === 1) {
        window.location.href="/dashboard";
    }
    const { clientId } = useParams();
    const [InputFields, setInputFields] = useState({
        fname: "",
        lname: "",
        phoneNo: "",
        email: "",
        address: "",
        pincode: "",
        stateId: "",
        cityId: "",
        statusId: "",
        generatedBy: ""
    });
    const [SubmitButton, setSubmitButton] = useState((clientId) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (clientId) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const handleStateConfig = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);

        let postData = {
            id: clientId,
            fname: data.fname,
            lname: data.lname,
            phoneNo: data.phoneNo,
            email: data.email,
            address: data.address,
            pincode: data.pincode,
            stateId: data.stateId,
            cityId: data.cityId,
            statusId: data.statusId
        }
        let apiCall = "";
        if(clientId) {
            apiCall = await ajaxCall.patchCall(`${config.api.generic}/clients`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${config.api.generic}/clients`, postData, token);
        }
        if(apiCall && apiCall.status) {
            toast.info('Record updated successfully');
            if(!clientId) {
                reset();
            }
            setSubmitButton('UPDATE RECORD');
            setDisableSubmitButton(false);
        } else {
            let msg = (apiCall && apiCall.hasOwnProperty('context')) ? apiCall.context.message : "Something went wrong";
            toast.error(msg);
            setSubmitButton('UPDATE RECORD');
            setDisableSubmitButton(false);
        }
    }

    // Get data from DB - Onload
    React.useEffect(() => {
        async function fetchData() {
            if(clientId) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/clients/row`, {
                    clientId: (clientId) ? clientId : 0
                }, token);
                if(apiCall && apiCall.status) {
                    let onLoadData = {
                        fname: apiCall.context.data[0].first_name,
                        lname: apiCall.context.data[0].last_name,
                        phoneNo: apiCall.context.data[0].phone_no,
                        email: apiCall.context.data[0].email,
                        address: apiCall.context.data[0].address,
                        pincode: apiCall.context.data[0].pincode,
                        stateId: apiCall.context.data[0].state_id,
                        cityId: apiCall.context.data[0].city_id,
                        statusId: apiCall.context.data[0].status_id,
                        generatedBy: apiCall.context.data[0].generated_by
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false, message: '' })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Client</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Client', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <a href="/clients" className="rwt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">VIEW CLIENTS</a>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1 ">
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-lg-5 p-md-2 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {(Notification.status) ?
                                            <div className="col-12 text-center">{Notification.message}</div> : <>
                                                <div className="col-md-12">
                                                    <div className="text-left mt-3 rwt-txt-dark-blue">
                                                        <h3><b>Clients Details</b></h3>
                                                        <div className='fs-14rem text-secondary'>Last change by: {InputFields.generatedBy}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">First Name</label>
                                                    <input type="text" defaultValue={InputFields.fname} className="form-control" name="fname" {...register("fname", { required: {
                                                        value: true,
                                                        message: "First name is mandatory"
                                                    }, minLength: {
                                                        value: 3,
                                                        message: "Enter atleast 3 characters"
                                                    }, maxLength: {
                                                        value: 25,
                                                        message: "Only 25 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.fname && errors.fname.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">Last Name</label>
                                                    <input type="text" defaultValue={InputFields.lname} className="form-control" name="lname" {...register("lname", { required: {
                                                        value: true,
                                                        message: "Last name is mandatory"
                                                    }, minLength: {
                                                        value: 1,
                                                        message: "Enter atleast 1 characters"
                                                    }, maxLength: {
                                                        value: 25,
                                                        message: "Only 25 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.lname && errors.lname.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">Phone Number</label>
                                                    <input type="text" defaultValue={InputFields.phoneNo} className="form-control" name="phoneNo" {...register("phoneNo", { required: {
                                                        value: true,
                                                        message: "Phone no is mandatory"
                                                    }, minLength: {
                                                        value: 10,
                                                        message: "Minimum 10 characters allowed"
                                                    }, maxLength: {
                                                        value: 10,
                                                        message: "Max 10 characters allowed"
                                                    }, pattern: {
                                                        value: /^[0-9+-]+$/,
                                                        message: "Enter valid mobile phone!"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.phoneNo && errors.phoneNo.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">Email</label>
                                                    <input type="text" defaultValue={InputFields.email} className="form-control" name="email" {...register("email", { required: false, pattern: {
                                                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                        message: "Invalid email address"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.email && errors.email.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <label className="form-label fs-14rem">Address</label>
                                                    <textarea defaultValue={InputFields.address} className="form-control" name="address" {...register("address", { required: {
                                                        value: true,
                                                        message: "Address is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Minimum 5 characters allowed"
                                                    }, maxLength: {
                                                        value: 250,
                                                        message: "Max 250 characters allowed"
                                                    } })}></textarea>
                                                    <small className="form-text text-danger">
                                                        {errors.address && errors.address.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">State</label>
                                                    <State register={register} errors={errors} selectedValue={InputFields.stateId} locationFunction={handleStateConfig} token={token} />
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">City</label>
                                                    <City register={register} errors={errors} selectedValue={InputFields.cityId} locationFunction={handleStateConfig} token={token} />
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Pincode</label>
                                                    <input type="text" defaultValue={InputFields.pincode} className="form-control" name="pincode" {...register("pincode", { required: {
                                                        value: true,
                                                        message: "Pincode is mandatory"
                                                    }, minLength: {
                                                        value: 6,
                                                        message: "Minimum 6 characters allowed"
                                                    }, maxLength: {
                                                        value: 6,
                                                        message: "Max 6 characters allowed"
                                                    }, pattern: {
                                                        value: /^[0-9+-]+$/,
                                                        message: "Enter valid Pincode!"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.pincode && errors.pincode.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Status</label>
                                                    <Status register={register} errors={errors} selectedValue={InputFields.statusId} locationFunction={handleStateConfig} token={token} />
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                </div>
                                            </>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
        </>
    )
}

export default Client;