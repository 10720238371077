// libs
import React, {useEffect, useState} from 'react';
// components 
import Loading from './loading';
// config & functions 
import ajaxCall from '../utils/ajaxcall';
import config from '../config';

function TotalCount({token, apipath, params = {}}) {
    const [Count, setCount] = useState(0);
    const [IsLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            loadCount();
        };
        fetchData();
    }, []);

    const loadCount = async () => {
        let apiData = await ajaxCall.getCall(`${config.api.generic}/kpi/${apipath}`, params, token);
        if(apiData && apiData.status) {
            setCount(apiData.context.data[0].total_count);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    if(IsLoading) {
        return (<Loading/>);
    } else {
        return Count;
    }
}

export default TotalCount;