// libs
import React, {useEffect, useState} from 'react'
// components
import Pagination from "react-js-pagination";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import "../../assets/css/style.css";
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Table from './table';
import Record404 from '../../components/record404';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function List({token, orgInfo}) {
	// Temp added, need to validate properly
    if(orgInfo && orgInfo.hasOwnProperty('industryId') && parseInt(orgInfo.industryId) === 1) {
        window.location.href="/dashboard";
    }
	const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [Error, setError] = useState(true);
	const [ActivePage, setActivePage] = useState(1);

	useEffect(() => {
        async function fetchData() {
			loadApiData({offset:0, limit: config.pagination.limitPerPage}); // This function also called in confirmation-box.js to reload data after delete
        };
        fetchData();
    }, []);

	const loadApiData = async (params) => {
		setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/clients`, params, token);
        if(apiData && apiData.status) {
            setListData(apiData.context.data.records);
            setTotalListCount(apiData.context.data.totalCount);
            setIsLoading(false);
			setError(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }
	
	const handlePageChange = async (pageNumber) => {
		setActivePage(pageNumber);
		let offset = (pageNumber*config.pagination.limitPerPage)-config.pagination.limitPerPage;
		loadApiData({offset, limit: config.pagination.limitPerPage})
	}

	const handleOnDelete = async (id) => {
		if(ListData && Array.isArray(ListData) && ListData.length > 0) {
			let newList = ListData.filter(function( obj ) {
				return obj.id !== id;
			});
			setError(false);
			if(newList.length > 0) {
				setListData(newList);
			} else {
				// go to -1 in pagination
				let pageNumber = ActivePage - 1;
				let offset = (pageNumber*config.pagination.limitPerPage)-config.pagination.limitPerPage;
				loadApiData({offset, limit: config.pagination.limitPerPage})
			}
		} else {
			setError(true);
		}
	}

	const PrintTable = () => {
		if(IsLoading) {
			return <Loading />
		} else if(Is404) {
			return <Record404 />
		} else if (Error) {
			return <div>Something went wrong. Please refresh your page</div>
		} else {
			return <Table list={ListData} token={token} handleondeletefn={handleOnDelete}/>
		}
	}


	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Clients List</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Clients', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <a href="/clients/create" className="rwt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">ADD CLIENTS</a>
                        </div>
                    </div>
                    <hr />
				</div>
				<div className="row">
					<div className="col">
						<PrintTable />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={ActivePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default List;