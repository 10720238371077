// libs
import React from 'react'
// components 
// ...
// config & functions 
import config from '../config';

function Logo({orgInfo}) {
    return (
        <div>
            <a href="/" className="navbar-brand"> <img src={`${config.assets.products}/${orgInfo.domain}/products/thumbnail/${orgInfo.logo}`} alt="" style={{maxHeight: '40px', maxWidth: '120px'}} /></a>
        </div>
    )
}
export default Logo;
