// libs
import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// components 
import Dashboard from './pages/dashboard';
import ForgotPassword from './pages/credentials/forgot-password.js'; 
import Login from './pages/credentials/login.js';
import ProductList from "./pages/products/list";
import ProductForm from "./pages/products/form";
import QuotationForm from "./pages/quotation/form";
import QuotationList from "./pages/quotation/list";
import ClientForm from "./pages/client/form";
import ClientList from "./pages/client/list";
import Account from "./pages/account/form";
import UploadImage from "./pages/uploads/image";
import Settings from "./pages/settings";
import HomePage from "./pages/home";
// config & functions 
import userToken from "./utils/user-token";
import utils from './utils';

const App = () => {
	const { token, setToken } = userToken();
	const [OrgInfo, setOrgInfo] = useState({logo: 'rwlogo-h.png', domain: ''});

	const validateToken = async () => {
		// decode jwt token and get OrgInfo
		if(token) {
			const tokenVal = await utils.decodeJWT(token);
			if(tokenVal && tokenVal.hasOwnProperty('data')) {
				const data = tokenVal.data;
				let logo = (data.logo) ? data.logo : null;
				let domain = (data.domain) ? data.domain : null;
				let industryId = (data.industryId) ? data.industryId : null;
				let foodTypeId = (data.foodTypeId) ? data.foodTypeId : null;
				setOrgInfo({logo: logo, domain: domain, industryId: industryId, foodTypeId: foodTypeId})
			}
		}
	}

	useEffect(() => {
		validateToken();
	}, [])

	if(!token) {
		return <Login setToken={setToken} />
	} else {
		return (
			<Router>
				<Routes>
					<Route exact path="/" element={<Dashboard token={token} orgInfo={OrgInfo} />} />
					<Route path="/forgot-password" element={<ForgotPassword token={token} orgInfo={OrgInfo} />} />
					<Route path="/dashboard" element={<Dashboard token={token} orgInfo={OrgInfo} />} />
					<Route path="/quotation/create" element={<QuotationForm token={token} orgInfo={OrgInfo} />} />
					<Route path="/quotation/edit/:clientId/:quotationId" element={<QuotationForm token={token} orgInfo={OrgInfo} />} />
					<Route path="/quotation" element={<QuotationList token={token} orgInfo={OrgInfo} />} />
					<Route path="/account" element={<Account token={token} orgInfo={OrgInfo} />} />
					<Route path="/products" element={<ProductList token={token} orgInfo={OrgInfo} />} />
					<Route path="/products/add" element={<ProductForm token={token} orgInfo={OrgInfo} />} />
					<Route path="/products/edit/:productId" element={<ProductForm token={token} orgInfo={OrgInfo} />} />
					<Route path="/upload-image" element={<UploadImage token={token} orgInfo={OrgInfo} />} />
					<Route path="/clients/create" element={<ClientForm token={token} orgInfo={OrgInfo} />} />
					<Route path="/clients/edit/:clientId" element={<ClientForm token={token} orgInfo={OrgInfo} />} />
					<Route path="/clients" element={<ClientList token={token} orgInfo={OrgInfo} />} />
					<Route path="/settings" element={<Settings token={token} orgInfo={OrgInfo} />} />
					<Route path="/home-page" element={<HomePage token={token} orgInfo={OrgInfo} />} />
				</Routes>
			</Router>
		)
	}
}

export default App;
