// libs
import React, { useEffect, useState, useRef } from 'react';
// components 
import Logo from './logo';
// css
// ...
// config & functions 
import utils from '../utils';

function Navbar({orgInfo}) {

    const ref = useRef(null);
    const [Collapsing, setCollapsing] = useState(false);
    const [ShowNav, setShowNav] = useState(false);
    const [UserDropdown, setUserDropdown] = useState(false);

    const handleLogout = () => {
        utils.removeLocalStorage('token');
        window.location.href = "/login";
    }

    const handleToggle = (val) => {
        setCollapsing(true);
        setTimeout(() => {
            setCollapsing(false);
            setShowNav(val);
        }, 50);
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && ref.current.classList.contains('show')) {
            handleToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    console.log(ShowNav)

    return (
        <div className="navbar navbar-expand-lg shadow-sm p-2 bg-body rounded sticky-top border-bottom">
            <div className="container">
                <Logo orgInfo={orgInfo} />
                <button type="button" className="navbar-toggler rwt-primary-btn" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => handleToggle(true)}>
                    <span className="">Menu</span>
                </button>
                <div className={`navbar-collapse ${Collapsing?`collapsing`:`collapse`} ${ShowNav?`show`:``} justify-content-end`} id="nav" ref={ref}>
                    <div className="navbar-nav ml-auto">
                        <ul className="navbar-nav bg-white m-0 ml-lg-auto p-3 p-lg-0">
                            <li className="d-inline d-lg-none">
                                <button type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" className="close float-end" onClick={() => handleToggle(false)}>&times;</button>
                            </li>
                            <li className="nav-item">
                                <a href="/dashboard" className="nav-link rwt-txt-dark-blue fw-bold">Home</a>
                            </li>
                            {(orgInfo && orgInfo.hasOwnProperty('industryId') && parseInt(orgInfo.industryId) === 2)? <>
                                <li className="nav-item">
                                    <a href="/clients" className="nav-link rwt-txt-dark-blue fw-bold">Clients</a>
                                </li>
                                <li className="nav-item">
                                    <a href="/quotation" className="nav-link rwt-txt-dark-blue fw-bold">Quotation</a>
                                </li>
                            </>:null}
                            <li className="nav-item">
                                <a href="/products" className="nav-link rwt-txt-dark-blue fw-bold">Products</a>
                            </li>
                            <li className="nav-item">
                                <a href="/upload-image" className="nav-link rwt-txt-dark-blue fw-bold">Uploads</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle rwt-txt-dark-blue fw-bold" href="#" onClick={(e) => setUserDropdown(!UserDropdown)} id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Account </a>
                                <div className={`dropdown-menu-end dropdown-menu ${(UserDropdown)?` show`:``}`} aria-labelledby="userDropdown">
                                    <a href="/account" className="dropdown-item">Account</a>
                                    <a href="/home-page" className="dropdown-item">Home Page</a>
                                    <a href="/settings" className="dropdown-item">Settings</a>
                                    <button className="dropdown-item text-danger" onClick={() => handleLogout()}>Logout</button>
                                </div>
                            </li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar;
