import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import TotalCount from "../../components/total-count";

function Card({token, title, subtitle, apipath, prefix, params}) {

    useEffect(() => {
        async function fetchData() {
        };
        fetchData();
    }, []);

    return <div className="card card-stats mb-4 mb-xl-0">
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">{title}</h5>
                    <div className="h2 font-weight-bold mb-0">{prefix} <TotalCount token={token} apipath={apipath} params={params} /></div>
                </div>
                <div className="col-auto">
                    <div className="icon icon-shape rwt-txt-light-blue">
                        <FontAwesomeIcon icon={faChartColumn} className="dashboard-card-icon" />
                    </div>
                </div>
            </div>
            <p className="mt-3 mb-0 text-muted text-sm">
                <span className="text-nowrap">{subtitle}</span>
            </p>
        </div>
    </div>
}

export default Card;