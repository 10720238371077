// libs
import React, {useEffect, useRef, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
// components 
// ... 
// config & functions 
import ajaxCall from '../utils/ajaxcall'
import config from '../config';

function ConfirmationBox({url, id, setrowid, token, handleondeletefn}) {

    const wrapperRef = useRef(null);
    const [IsDelete, setIsDelete] = useState(false);

    const hideModal = () => {
        setrowid(0); // make it 0 and remove component from parent div
	}

    const initDeleteRecord = async () => {
        setIsDelete(true);
        const apiCall = await ajaxCall.deleteCall(`${url}`, {id: id}, token);
        if(apiCall && apiCall.status) {
            setIsDelete(false);
            toast.info('Record deleted successfully');
            setTimeout(() => {
                handleondeletefn(id);
                hideModal();
            }, 1500);
        } else {
            setIsDelete(false);
            let msg = (apiCall && apiCall.hasOwnProperty('context')) ? apiCall.context.message : "Something went wrong";
            toast.error(msg);
        }
    }

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Confirmation Box</h5>
                        <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                    </div>
                    <div className="modal-body fs-14rem">
                        Are you sure you want to delete this record?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        <button type="button" className="btn btn-primary fs-14rem" disabled={IsDelete} onClick={() => initDeleteRecord()}>{(IsDelete)?'Deleting...':'Delete'}</button>
                    </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}
export default ConfirmationBox;
