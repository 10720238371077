// libs
import React, { useState } from 'react';
// components
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Breadcrumb from "../../components/breadcrumb";
import GalleryBox from '../../components/gallery-box';
import ImagePreviewer from '../../components/image-previewer';
import State from "../../components/state";
import City from '../../components/city';
// css
import "../../assets/css/form.css";
import "react-datepicker/dist/react-datepicker.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";

function Account({token, orgInfo}) {
    const { productId } = useParams();

    const [InputFields, setInputFields] = useState({
        logo: "",
        name: "",
        address: "",
        pincode: "",
        stateId: "",
        cityId: "",
        email: "",
        phone: "",
        website: "",
        urlFacebook: "",
        urlInstagram: "",
        urlLinkedIn: "",
        urlYoutube: "",
        urlGoogleReview: "",
        urlGeoLocation: ""
    });
    const [SubmitButton, setSubmitButton] = useState('UPDATE RECORD');
    const [Notification, setNotification] = useState({ status: true, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [PreviewImage, setPreviewImage] = useState('');
    const [BrowseImage, setBrowseImage] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const handleStateConfig = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const handleLogoImageName=((val)=>{ 
        handleStateConfig('logo', val) 
    })

    const removeLogo = () => {
        handleStateConfig('logo', '') 
    }

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);

        let postData = {
            logo: InputFields.logo,
            name: data.name,
            address: data.address,
            pincode: data.pincode,
            stateId: data.stateId,
            cityId: data.cityId,
            email: data.email,
            phone: data.phone,
            website: data.website,
            urlFacebook: data.urlFacebook,
            urlInstagram: data.urlInstagram,
            urlLinkedIn: data.urlLinkedIn,
            urlYoutube: data.urlYoutube,
            urlGoogleReview: data.urlGoogleReview,
            urlGeoLocation: data.urlGeoLocation
        }

        // By default, we have only patch call as post call will be add by master admin (RapidWild) while creating account 
        let apiCall = await ajaxCall.patchCall(`${config.api.generic}/account`, postData, token);
        if(apiCall && apiCall.status) {
            toast.info('Record updated successfully');
            reset();
            setSubmitButton('UPDATE RECORD');
            setDisableSubmitButton(false);
        } else {
            let msg = (apiCall && apiCall.hasOwnProperty('context')) ? apiCall.context.message : "Something went wrong";
            toast.error(msg);
            setSubmitButton('UPDATE RECORD');
            setDisableSubmitButton(false);
        }
    }

    // Get data from DB - Onload
    React.useEffect(() => {
        async function fetchData() {
            let apiCall = await ajaxCall.getCall(`${config.api.generic}/account/row`, {
                productId: (productId) ? productId : 0
            }, token);
            if(apiCall && apiCall.status) {
                let onLoadData = {
                    logo: apiCall.context.data[0].logo,
                    name: apiCall.context.data[0].name,
                    address: apiCall.context.data[0].address,
                    pincode: apiCall.context.data[0].pincode,
                    stateId: apiCall.context.data[0].state_id,
                    cityId: apiCall.context.data[0].city_id,
                    email: apiCall.context.data[0].email,
                    phone: apiCall.context.data[0].phone_no,
                    website: apiCall.context.data[0].website,
                    urlFacebook: apiCall.context.data[0].facebook_url,
                    urlInstagram: apiCall.context.data[0].instagram_url,
                    urlLinkedIn: apiCall.context.data[0].linkedin_url,
                    urlYoutube: apiCall.context.data[0].youtube_url,
                    urlGoogleReview: apiCall.context.data[0].googlereview_url,
                    urlGeoLocation: apiCall.context.data[0].geolocation_url
                }
                setInputFields(onLoadData)
                setNotification({ status: false, message: '' })
            } else {
                setNotification({ status: false, message: '' })
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Account</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Account', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <a href="/dashboard" className="rwt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">DASHBOARD</a>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1 ">
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-lg-5 p-md-2 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {(Notification.status) ?
                                            <div className="col-12 text-center">{Notification.message}</div> : <>
                                                <div className="col-md-12">
                                                    <div className="text-left mt-3 rwt-txt-dark-blue">
                                                        <h3><b>Account Details</b></h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 mt-3">
                                                    <label className="form-label fs-14rem">Org Name</label>
                                                    <input type="text" defaultValue={InputFields.name} className="form-control" name="name" {...register("name", { required: {
                                                        value: true,
                                                        message: "Org name is mandatory"
                                                    }, minLength: {
                                                        value: 3,
                                                        message: "Enter atleast 3 characters"
                                                    }, maxLength: {
                                                        value: 85,
                                                        message: "Only 85 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.name && errors.name.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <div>
                                                        <label className="form-label fs-14rem">Logo</label>
                                                    </div>
                                                    <div className="pt-1">
                                                        <button type="button" className="btn btn-sm btn-primary" onClick={() => setBrowseImage(true)}>Browse Gallery</button>
                                                    </div>
                                                    {(InputFields.logo)? <div><span className='text-primary fs-14rem cursor-pointer' onClick={() => setPreviewImage(`${config.assets.products}/${orgInfo.domain}/products/medium/${InputFields.logo}`)}>Preview Logo</span><span onClick={removeLogo} className='ms-2 text-danger fs-14rem cursor-pointer'>Delete Logo</span></div>:null}
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Email</label>
                                                    <input type="text" defaultValue={InputFields.email} className="form-control" name="email" {...register("email", { required: {
                                                        value: true,
                                                        message: "Email is mandatory"
                                                    }, pattern: {
                                                        value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                        message: "Invalid email address"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.email && errors.email.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Phone</label>
                                                    <input type="text" defaultValue={InputFields.phone} className="form-control" name="phone" {...register("phone", { required: {
                                                        value: true,
                                                        message: "Phone no is mandatory"
                                                    }, minLength: {
                                                        value: 10,
                                                        message: "Minimum 10 characters allowed"
                                                    }, maxLength: {
                                                        value: 10,
                                                        message: "Max 10 characters allowed"
                                                    }, pattern: {
                                                        value: /^[0-9+-]+$/,
                                                        message: "Enter valid mobile phone!"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.phone && errors.phone.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Website</label>
                                                    <input type="text" defaultValue={InputFields.website} className="form-control" name="website" {...register("website", { required: {
                                                        value: false,
                                                        message: "Website is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Minimum 5 characters allowed"
                                                    }, maxLength: {
                                                        value: 125,
                                                        message: "Max 125 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.website && errors.website.message}
                                                    </small>
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">State</label>
                                                    <State register={register} errors={errors} selectedValue={InputFields.stateId} locationFunction={handleStateConfig} token={token} />
                                                </div>
                                                <div className="col-6 col-lg-4 col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">City</label>
                                                    <City register={register} errors={errors} selectedValue={InputFields.cityId} locationFunction={handleStateConfig} token={token} />
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label className="form-label fs-14rem">Pincode</label>
                                                    <input type="text" defaultValue={InputFields.pincode} className="form-control" name="pincode" {...register("pincode", { required: {
                                                        value: true,
                                                        message: "Pincode is mandatory"
                                                    }, minLength: {
                                                        value: 6,
                                                        message: "Minimum 6 characters allowed"
                                                    }, maxLength: {
                                                        value: 6,
                                                        message: "Max 6 characters allowed"
                                                    }, pattern: {
                                                        value: /^[0-9+-]+$/,
                                                        message: "Enter valid Pincode!"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.pincode && errors.pincode.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <label className="form-label fs-14rem">Address</label>
                                                    <textarea defaultValue={InputFields.address} className="form-control" name="address" {...register("address", { required: {
                                                        value: true,
                                                        message: "Address is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Minimum 5 characters allowed"
                                                    }, maxLength: {
                                                        value: 250,
                                                        message: "Max 250 characters allowed"
                                                    } })}></textarea>
                                                    <small className="form-text text-danger">
                                                        {errors.address && errors.address.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="text-left mt-3 rwt-txt-dark-blue">
                                                        <h3><b>Socail Media Links</b></h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label fs-14rem">Facebook URL</label>
                                                    <input type="text" defaultValue={InputFields.urlFacebook} className="form-control" name="urlFacebook" {...register("urlFacebook", { required: {
                                                        value: false,
                                                        message: "Org name is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Enter atleast 5 characters"
                                                    }, maxLength: {
                                                        value: 180,
                                                        message: "Only 180 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.urlFacebook && errors.urlFacebook.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label fs-14rem">Instagram URL</label>
                                                    <input type="text" defaultValue={InputFields.urlInstagram} className="form-control" name="urlInstagram" {...register("urlInstagram", { required: {
                                                        value: false,
                                                        message: "Org name is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Enter atleast 5 characters"
                                                    }, maxLength: {
                                                        value: 180,
                                                        message: "Only 180 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.urlInstagram && errors.urlInstagram.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">LinkedIn URL</label>
                                                    <input type="text" defaultValue={InputFields.urlLinkedIn} className="form-control" name="urlLinkedIn" {...register("urlLinkedIn", { required: {
                                                        value: false,
                                                        message: "Org name is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Enter atleast 5 characters"
                                                    }, maxLength: {
                                                        value: 180,
                                                        message: "Only 180 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.urlLinkedIn && errors.urlLinkedIn.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">Youtube URL</label>
                                                    <input type="text" defaultValue={InputFields.urlYoutube} className="form-control" name="urlYoutube" {...register("urlYoutube", { required: {
                                                        value: false,
                                                        message: "Org name is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Enter atleast 5 characters"
                                                    }, maxLength: {
                                                        value: 250,
                                                        message: "Only 250 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.urlYoutube && errors.urlYoutube.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">Google Review URL</label>
                                                    <input type="text" defaultValue={InputFields.urlGoogleReview} className="form-control" name="urlGoogleReview" {...register("urlGoogleReview", { required: {
                                                        value: false,
                                                        message: "Google review is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Enter atleast 5 characters"
                                                    }, maxLength: {
                                                        value: 180,
                                                        message: "Only 180 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.urlGoogleReview && errors.urlGoogleReview.message}
                                                    </small>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem">Geo Location URL</label>
                                                    <input type="text" defaultValue={InputFields.urlGeoLocation} className="form-control" name="urlGeoLocation" {...register("urlGeoLocation", { required: {
                                                        value: false,
                                                        message: "Geo Location is mandatory"
                                                    }, minLength: {
                                                        value: 5,
                                                        message: "Enter atleast 5 characters"
                                                    }, maxLength: {
                                                        value: 340,
                                                        message: "Only 340 characters allowed"
                                                    } })} />
                                                    <small className="form-text text-danger">
                                                        {errors.urlGeoLocation && errors.urlGeoLocation.message}
                                                    </small>
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button type="submit" disabled={DisableSubmitButton} className="rwt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                    {(PreviewImage)? <ImagePreviewer previewimage={PreviewImage} setpreviewimage={setPreviewImage} />:null}
                                                </div>
                                            </>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
            {(BrowseImage)? <GalleryBox orgInfo={orgInfo} handlegalleryimagenamefn={handleLogoImageName} setbrowseimage={setBrowseImage} token={token} action="selectable" />:null}
        </>
    )
}

export default Account;