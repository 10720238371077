// libs
import React, {useEffect, useState, useRef} from 'react'
// components
import Pagination from "react-js-pagination";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import "../../assets/css/style.css";
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Table from './table';
import Record404 from '../../components/record404';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function List({token, orgInfo}) {
	const searchBoxRef = useRef();
	const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [Error, setError] = useState(true);
	const [ActivePage, setActivePage] = useState(1);
	const [AppliedSearchKeyword, setAppliedSearchKeyword] = useState("");

	const loadApiData = async (params) => {
		setIsLoading(true);
        let apiData = await ajaxCall.getCall(`${config.api.generic}/products`, params, token);
        if(apiData && apiData.status) {
            setListData(apiData.context.data.records);
            setTotalListCount(apiData.context.data.totalCount);
            setIsLoading(false);
			setError(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }
	
	const handlePageChange = async (pageNumber) => {
		setActivePage(pageNumber);
		let offset = (pageNumber*config.pagination.limitPerPage)-config.pagination.limitPerPage;
		loadApiData({searchKeyword: AppliedSearchKeyword, offset, limit: config.pagination.limitPerPage})
	}

	const handleOnDelete = async (id) => {
		if(ListData && Array.isArray(ListData) && ListData.length > 0) {
			let newList = ListData.filter(function( obj ) {
				return obj.id !== id;
			});
			setError(false);
			if(newList.length > 0) {
				setListData(newList);
			} else {
				// go to -1 in pagination
				let pageNumber = ActivePage - 1;
				let offset = (pageNumber*config.pagination.limitPerPage)-config.pagination.limitPerPage;
				loadApiData({searchKeyword: AppliedSearchKeyword, offset, limit: config.pagination.limitPerPage})
			}
		} else {
			setError(true);
		}
	}

	const noSearch = () => {}
	
	const removeSearchKeyword = () => {
        setAppliedSearchKeyword("");
    }

	const PrintTable = () => {
		if(IsLoading) {
			return <Loading />
		} else if(Is404) {
			return <Record404 />
		} else if (Error) {
			return <div>Something went wrong. Please refresh your page</div>
		} else {
			return <Table orgInfo={orgInfo} list={ListData} token={token} handleondeletefn={handleOnDelete}/>
		}
	}

	useEffect(() => {
        async function fetchData() {
			loadApiData({searchKeyword: '', offset:0, limit: config.pagination.limitPerPage}); // This function also called in confirmation-box.js to reload data after delete
        };
        fetchData();
    }, []);

	useEffect(() => {
        async function fetchData() {
			loadApiData({searchKeyword: AppliedSearchKeyword, offset:0, limit: config.pagination.limitPerPage}); // This function also called in confirmation-box.js to reload data after delete
        };
        fetchData();
    }, [AppliedSearchKeyword]);

	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left rwt-txt-dark-blue">Products List</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Products', url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <a href="/products/add" className="rwt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">ADD PRODUCTS</a>
                        </div>
                    </div>
                    <hr />
				</div>
				<div className="row">
					<div className="col-12 col-md-6 col-lg-5 mt-2 offset-md-6 offset-lg-7">
						<div className="input-group input-group-sm pt-2 pb-3">
							<input type="text" defaultValue={AppliedSearchKeyword} className="form-control" ref={searchBoxRef} name="searchKeyword" aria-label="Search keywords" aria-describedby="search-button" placeholder="Search keywords" />
							<button type="submit" id="search-button" className="rwt-primary-btn text-white btn" onClick={() => {(searchBoxRef.current.value)?setAppliedSearchKeyword(searchBoxRef.current.value):noSearch()}}>SEARCH</button>
						</div>
					</div>
					{(AppliedSearchKeyword !== "") ? <div className="col-12"><strong>Search: </strong> <button type='button' className='my-3 btn py-1 px-1 btn-md fs-13rem text-dark bg-light border border-1 border-secondary rounded'>{AppliedSearchKeyword} <span onClick={removeSearchKeyword}>X</span></button></div> : null}
				</div>
				<div className="row">
					<div className="col">
						<PrintTable />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={ActivePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default List;