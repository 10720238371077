// libs
import React, {useState} from 'react'
// components 
import ImagePreviewer from "../../components/image-previewer"
// config & functions 
import config from '../../config';
import utils from '../../utils';

function Preview({data, domain, setshowpreview}) {

    const [PreviewImage, setPreviewImage] = useState('');

    const ta = parseInt(data.totalPrice?.total_amount || 0);
    const td = parseInt(data.totalPrice?.discount || 0);
    const gst = parseInt(data.totalPrice?.gst || 0);
    const totalGST = (ta * parseInt(gst)/100)+ta;
    const grandTotalAfterDiscount = totalGST * ((100-td) / 100);
    const printTotal = utils.numberWithCommas(grandTotalAfterDiscount);
    const tp = parseInt(data.totalPrice?.total_paid || 0);
    const printTotalPaid = utils.numberWithCommas(tp);
    const balancePayment = utils.numberWithCommas(grandTotalAfterDiscount - tp);

    const MenuList = ({menu}) => {
        if(menu && Array.isArray(menu) && menu.length > 0) {
            return menu.map(ele => {
                return <div key={Math.random()} className='py-2 d-flex'>
                    <img src={`${config.assets.products}/${domain}/products/thumbnail/${ele.image}`} onClick={() => setPreviewImage(`${config.assets.products}/${domain}/products/medium/${ele.image}`)} className='me-2 rounded-circle border border-2 cursor-pointer' style={{width: '55px', height: '55px'}} />
                    <div>
                        <div><span className='fs-16rem text-dark fw-bold'>{ele.name}</span> {(ele.tagName)?<span className='border-danger rounded bg-danger p-1 fs-11rem text-white fw-bold'>{ele.tagName}</span>:null}</div>
                        <div className='fs-14rem text-secondary'>{ele.description}</div>
                        {(ele.comments) ? <div className='fs-14rem fst-italic'>Comments: {ele.comments}</div> : null}
                    </div>
                </div>
            })
        } else {
            return null;
        }
    }

    const FormatedAMPM = ({date}) => {
        return utils.getYYMMDD_AMPM(date)
    }

    const MenuSection = ({section}) => {
        if(section && Array.isArray(section) && section.length > 0) {
            return section.map(ele => {
                return <section key={Math.random()} className='mt-2'>
                    <div className='py-2 border-bottom fs-16rem fw-bold'><FormatedAMPM date={ele.startDate} /> - <span className='rwt-txt-orange'>for {ele.members} members</span></div>
                    <MenuList menu={ele.selectedItems} />
                </section>
            })
        } else {
            return null;
        }
    }
    
    const SplitPrice = ({list}) => {
        let data = JSON.parse(list);
        if(data && Array.isArray(data) && data.length > 0) {
            return <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th scope="col">Label</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(ele => {
                        return <tr key={Math.random()}><td>{ele.label}</td><td>{ele.quantity}</td><td>{ele.price}/-</td><td>{ele.total}/-</td></tr>
                    })}
                </tbody>
            </table>
        } else {
            return null;
        }
    }

    const AllPayments = ({list}) => {
        let pData = JSON.parse(list);
        if(pData && Array.isArray(pData) && pData.length > 0) {
            return <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th scope="col">Label</th>
                        <th scope="col">Price</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {pData.map(ele => {
                        return <tr key={Math.random()}><td>{ele.label}</td><td>{ele.price}/-</td><td>{ele.date}</td></tr>
                    })}
                    <tr><td colSpan={3} className="text-end"><span className='fs-14rem fw-bold'>Total Paid: {printTotalPaid}/-</span></td></tr>
                    <tr><td colSpan={3} className="text-end"><span className='fs-16rem fw-bold rwt-txt-orange'>Balance: {balancePayment}/-</span></td></tr>
                </tbody>
            </table>
        } else {
            return null;
        }
    }

    const download = (type) => {
        window.open(`${config.api.pdfExport}/static/pdf?type=${type}&data=${JSON.stringify(data)}`, "_blank", "toolbar=no,scrollbars=no,resizable=no,width=400,height=400");
    }

    return (
        <>
            <div className="modal fade show modal-xl" data-dismiss="modal" tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.6)'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Quotation List</h5>
                        <button type="button" className="btn-close" onClick={()=>setshowpreview(false)} aria-label="Close"></button>
                    </div>
                    <div className="modal-body fs-14rem">
                        <section>
                            <h6 className='text-center text-secondary'>{data.otherInfo.first_name} {data.otherInfo.last_name}'s</h6>
                            <h4 className='text-center'>{data.otherInfo.reference_name}</h4>
                            <div className='fs-14rem text-dark text-center'>{data.otherInfo.start_date} - {data.otherInfo.end_date}</div>
                            <div className='fs-14rem text-dark text-center'>{data.otherInfo.address}, {data.otherInfo.city_label}, {data.otherInfo.state_label}, {data.otherInfo.pincode}</div>
                            <div className='fs-14rem text-dark text-center'>Ph No: {data.otherInfo.phone_no}</div>
                        </section>
                        <div className='px-3 py-2'><MenuSection section={data.menuList} /></div>
                        {(data.totalPrice.split_total_amount && data.totalPrice.split_total_amount !== "")?<div className='px-3 py-2 border-top'><h5>Price Breakup: </h5><SplitPrice list={data.totalPrice.split_total_amount} /></div>:null}
                        {grandTotalAfterDiscount?<div className='border-top py-3 d-flex flex-column text-end'>
                            {ta?<span className='fs-16rem fw-bold'>Total Price: {utils.numberWithCommas(ta)}/-</span>:null}
                            {gst?<span className='fs-14rem fw-bold'>GST: {gst}%</span>:null}
                            {td?<span className='fs-14rem fw-bold'>Discount: {td}%</span>:null}
                            {<span className='fs-16rem fw-bold rwt-txt-orange'>Grand Total: {printTotal}/-</span>}
                        </div>:null}
                        {(data.totalPrice.all_payments && data.totalPrice.all_payments !== "")?<div className='px-3 py-2 border-top'><h5>Payment History: </h5><AllPayments list={data.totalPrice.all_payments} /></div>:null}
                        {data.otherInfo.terms?<div className='px-3 py-2 border-top'><h5>Terms: </h5>{data.otherInfo.terms}</div>:null}
                        <section className='border-top py-2 fs-14rem'>
                            <div className='text-center'><span className='fw-bold'>Service by: </span> {data.vendor[0].name}</div>
                            <div className='text-center'>{data.vendor[0].address}, {data.vendor[0].city}, {data.vendor[0].state}, {data.vendor[0].pincode}</div>
                            <div className='text-center'>Ph No: {data.vendor[0].phone_no}, Website: {data.vendor[0].website}</div>
                        </section>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="rwt-primary-btn btn btn-outline-none text-white fs-14rem" onClick={e => download('client')}>CLIENT DOWNLOAD</button>
                        <button type="button" className="rwt-primary-btn btn btn-outline-none text-white fs-14rem" onClick={e => download('team')}>TEAM DOWNLOAD</button>
                        <button type="button" className="btn btn-secondary fs-14rem" onClick={()=>setshowpreview(false)}>Close</button>
                    </div>
                    </div>
                </div>
            </div>
            {(PreviewImage)? <ImagePreviewer previewimage={PreviewImage} setpreviewimage={setPreviewImage} />:null}
        </>
    )
}
export default Preview;
