// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
// components 
import ConfirmationBox from '../../components/item-deletion-confirm-box';
import ImagePreviewer from '../../components/image-previewer';
// config & functions 
import config from '../../config';

function Table({orgInfo, list, token, handleondeletefn}) {
    const [RowId, setRowId] = useState(0);
    const deleteUrl = `${config.api.generic}/products`;
    const [PreviewImage, setPreviewImage] = useState('');

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table table-striped fs-14rem">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Image</th>
                            <th>MRP</th>
                            <th style={{minWidth: '110px'}}>Discount(%)</th>
                            <th style={{minWidth: '150px'}}>After Discount(%)</th>
                            <th>Status</th>
                            <th style={{width: '60px'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(data => {
                            return <tr key={Math.random()}>
                                <td><div style={{minWidth: '150px'}}>{data.name} {(typeof data.tag_name !== null && data.tag_name)?<span className="rounded-pill py-1 px-2 fs-11rem fw-bold bg-danger text-white">{data.tag_name}</span>:null}</div></td>
                                <td>{(data.image)? <img src={`${config.assets.products}/${orgInfo.domain}/products/thumbnail/${data.image}`} width="34px" height="34px" onClick={() => setPreviewImage(`${config.assets.products}/${orgInfo.domain}/products/medium/${data.image}`)} className="rounded-circle border border-2 cursor-pointer" /> : '-'}</td>
                                <td style={{minWidth: '80px'}}>Rs {data.mrp}/-</td>
                                <td>{data.discount}%</td>
                                <td>Rs {data.price_after_discount}/-</td>
                                <td>{data.status}</td>
                                <td>
                                    <div style={{width: '80px'}}>
                                        <a href={`/products/edit/${data.id}`} className="me-3"><FontAwesomeIcon icon={faPen} className="dashboard-card-icon" /></a>
                                        <button onClick={() => setRowId(data.id)} className="border-0 bg-transparent"><FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></button>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {(RowId) ? <ConfirmationBox url={deleteUrl} id={RowId} setrowid={setRowId} token={token} handleondeletefn={handleondeletefn} /> : null}
            {(PreviewImage)? <ImagePreviewer previewimage={PreviewImage} setpreviewimage={setPreviewImage} />:null}
        </>
    } else {
        return null;
    }
}

export default Table;