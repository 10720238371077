import jwtDecode from "jwt-decode";

const writeLocalStorage = async (key, data) => {
    return localStorage.setItem(key, data);
}

const readLocalStorage = async (key) => {
    return localStorage.getItem(key);
}

const removeLocalStorage = async (key) => {
    return localStorage.removeItem(key);
}

const decodeJWT = async (token) => {
    return jwtDecode(token)
}

const logout = () => {
    removeLocalStorage('token');
    window.location.href = "/login";
}

const urlDecode = async (url) => {
    if(url) {
        return decodeURIComponent(url.replace(/\+/g, ' '));
    } else {
        return '';
    }
}

const fileNameFromPath = async (str) => {
    return str.substring(str.lastIndexOf('/')+1); // this line has hardcoded in components/gallery-box.js file to avoid promise function inside return
}

const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

const setCookie = (name, value, options = {}) => {
    options = {
        path: '/',
        ...options
    };
  
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
}
// Example of use:
// setCookie('user', 'John', {secure: true, 'max-age': 3600});

const deleteCookie = (name) => {
    setCookie(name, "", {
        'max-age': -1
    })
}

const getYYMMDD_AMPM = (date) => {
    var dt = new Date(date);
    const year  = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, "0");
    const day   = dt.getDate().toString().padStart(2, "0");
    var hours = dt.getHours();
    var minutes = dt.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    const fDate = `${year}-${month}-${day} ${strTime}`;
    return fDate;
}

const getYYMMDD_HHMM_FromDate = (date) => {
    var dt = new Date(date);
    const year  = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, "0");
    const day   = dt.getDate().toString().padStart(2, "0");
    const hours   = dt.getHours().toString().padStart(2, "0");
    const minutes   = dt.getMinutes().toString().padStart(2, "0");
    const fDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return fDate;
}

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export default {
    writeLocalStorage, 
    readLocalStorage,
    removeLocalStorage,
    decodeJWT,
    logout,
    urlDecode,
    fileNameFromPath,
    getYYMMDD_AMPM,
    getYYMMDD_HHMM_FromDate,
    getCookie,
    setCookie,
    deleteCookie,
    numberWithCommas
}